const caseActionStatus = {
  COMPLETE: {
    label: "Complete",
    className: "complete",
  },
  PENDING: {
    label: "Pending",
    className: "pending",
  },
  OPEN: {
    label: "Open",
    className: "open",
  },
};

const getCaseActionStatusLabel = (action) => {
  if (action.completedOn != null) return caseActionStatus.COMPLETE.label;

  if (new Date() < new Date(action.due)) return caseActionStatus.PENDING.label;

  return caseActionStatus.OPEN.label;
};

const getCaseActionStatusClassName = (action) => {
  if (action.completedOn != null) return caseActionStatus.COMPLETE.className;

  if (new Date() < new Date(action.due))
    return caseActionStatus.PENDING.className;

  return caseActionStatus.OPEN.className;
};

export {
  caseActionStatus,
  getCaseActionStatusLabel,
  getCaseActionStatusClassName,
};

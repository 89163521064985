import React from "react";
import { Grid } from "@material-ui/core";
import CasesTableCard from "../components/CasesTableCard";
import { useTracking } from "react-tracking";
import PageBase from "../../components/PageBase";

function Cases() {
  const { Track } = useTracking({
    page: `Cases`,
  });

  return (
    <PageBase>
      <Track>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CasesTableCard />
          </Grid>
        </Grid>
      </Track>
    </PageBase>
  );
}

export default Cases;

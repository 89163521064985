import React from "react";
import {
  Button as MuiButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: (props) =>
      props?.variant === "contained" &&
      props?.color &&
      theme.palette[props.color].main,
    borderColor: (props) =>
      props?.variant === "outlined" &&
      props?.color &&
      theme.palette[props.color].main,
    "&:hover": {
      backgroundColor: (props) =>
        props?.variant === "contained" &&
        props?.color &&
        theme.palette[props.color].dark,
    },
    color: (props) =>
      props?.variant === "contained"
        ? theme.palette.getContrastText(
            props?.color
              ? theme.palette[props.color].main
              : theme.palette.text.primary
          )
        : props?.color
        ? theme.palette[props.color].main
        : theme.palette.text.primary,
    position: "relative",
    "&:disabled": {
      backgroundColor: (props) =>
        props?.variant === "contained" &&
        props?.color &&
        theme.palette[props.color].main,
    },
  },
  spinnerWrapper: {
    minHeight: "24.5px",
  },
  spinner: {
    position: "absolute",
    color: (props) =>
      props?.variant === "contained"
        ? theme.palette.getContrastText(
            props?.color
              ? theme.palette[props.color].main
              : theme.palette.text.primary
          )
        : props?.color
        ? theme.palette[props.color].main
        : theme.palette.text.primary,
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CustomButton = ({ isLoading = false, children, className, disabled, ...rest  }) => {
  const classes = useStyles(rest);

  return (
    <>
      <MuiButton {...rest} className={`${classes.button} ${className}`} disabled={isLoading || disabled}>
        {isLoading ? (
          <div className={classes.spinnerWrapper}>
            <CircularProgress size={24} className={classes.spinner} />
          </div>
        ) : (
          children
        )}
      </MuiButton>
    </>
  );
};

export default CustomButton;

import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useIncidentForm = ({
  siteExternalId,
  incidentId,
  incidentType,
  accountId,
  onSuccess,
}) =>
  useQuery(
    [queryKeys.incidentForm, siteExternalId, incidentId, incidentType, accountId],
    () =>
      templateService.fetchIncidentTemplate({
        siteExternalId,
        incidentId,
        incidentType,
        accountId,
      }),
    {
      enabled:
        !!siteExternalId && (!!incidentId || typeof incidentType === "number"),
      onSuccess: (data) => onSuccess(data),
    }
  );

export default useIncidentForm;

import React, { useState, useEffect } from "react";
import Avatar from "../../components/Avatar";
import {
  Badge,
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { MoreHoriz, PhoneForwarded as CallbackIcon } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import CardBase from "../../components/cards/CardBase";
import RequestCallbackDialog from "./RequestCallbackDialog";

const useStyles = makeStyles(() => ({
  userPhoto: {
    marginRight: "8px",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  iconButton: {
    padding: "2px",
    margintop: "-2px",
    marginleft: "2px",
    minwidth: "0",
    color: grey[700],
  },
  boxCenter: {
    textAlign: "center",
  },
  callbackIcon: {
    marginRight: "6px",
    color: grey[700],
  },
}));

const LawyerCard = ({ caseAdvisers }) => {
  const classes = useStyles();
  const [anchorCallback, setAnchorCallback] = useState(null);
  const [callbackAdviser, setCallbackAdviser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenContextMenu = (adviser, event) => {
    setCallbackAdviser(adviser);
    setAnchorCallback(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setAnchorCallback(null);
    setCallbackAdviser(null);
  };

  const handleDisplayCallbackDialog = () => {
    setAnchorCallback(null);
    setIsOpen(true);
  };

  const ViewAdviser = (adviser) => (
    <Box
      key={adviser.id}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={5}
      mb={2}
      ml={2}
      mr={2}
      className={classes.boxCenter}
    >
      <Box alignSelf="flex-end">
        <Button
          className={classes.iconButton}
          onClick={(ev) => handleOpenContextMenu(adviser, ev)}
        >
          <MoreHoriz />
        </Button>
      </Box>
      <Box>
        <Tooltip title={adviser.isPrimary ? "Primary Adviser" : ""}>
          {adviser.isPrimary ? (
            <Badge
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Avatar
                alt={adviser.name}
                size="sm"
                src={adviser.photo}
                className={classes.userPhoto}
              />
            </Badge>
          ) : (
            <Avatar
              alt={adviser.name}
              size="sm"
              src={adviser.photo}
              className={classes.userPhoto}
            />
          )}
        </Tooltip>
      </Box>
      <Typography variant="subtitle2">{adviser.name}</Typography>
      <Typography variant="subtitle2">{adviser.position}</Typography>
    </Box>
  );

  return (
    <>
      <CardBase title="Advisers">
        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          {caseAdvisers?.map(ViewAdviser)}
        </Box>
      </CardBase>
      <Menu
        anchorEl={anchorCallback}
        keepMounted
        open={Boolean(anchorCallback)}
        onClose={handleCloseContextMenu}
      >
        <MenuItem
          onClick={() => handleDisplayCallbackDialog(callbackAdviser.id)}
        >
          <CallbackIcon className={classes.callbackIcon} />
          {" Request Callback "}
        </MenuItem>
      </Menu>
      <RequestCallbackDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        advisers={caseAdvisers}
        selectedAdviserId={callbackAdviser?.id}
      />
    </>
  );
};

export default LawyerCard;

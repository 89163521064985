import { Dialog, Button, DialogTitle, DialogContent, DialogActions, Typography, makeStyles } from '@material-ui/core'
import { useCreateActionContext } from "../../contexts/CreateActionContext"
import { useSnackbar } from 'notistack';
import ActionForm from './ActionForm';
import useCreateAction from '../../hooks/mutations/useCreateAction';
import { WifiTethering as OnTheSpotIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    csvLink: {
        visibility: "hidden",
    },
    onTheSpotIcon: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    header: {
        display: "flex",
        alignItems: "center",
    },
    headerText: {
        marginLeft: theme.spacing(1),
    },
}));

export default function CreateActionDialog({ isModule, module, open, onCancel }) {
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();
    const { 
        actionDetails, 
        reset,
    } = useCreateActionContext()

    function handleCancel() {
        reset();
        onCancel();
    }

    const createAction = useCreateAction()

    function handleCreateAction(e) {
        e.preventDefault()

        createAction.mutate({
            ...actionDetails,
        })
         reset()
        enqueueSnackbar('On-the-Spot Action created successfully', { variant: 'success' })
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >  
            <form
                onSubmit={(e) => handleCreateAction(e)}
                onReset={handleCancel}
            >
                <DialogTitle>
                    <div className={classes.header}>
                        <OnTheSpotIcon />
                        <Typography className={classes.headerText}>
                            Add On-the-Spot Action
                        </Typography>
                    </div> 
                </DialogTitle>
                  <DialogContent>
                    <ActionForm
                        isModule={isModule}
                        module={module.module}
                    />
                  </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color="primary"
                        variant="text"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
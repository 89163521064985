import {
  Tune as DashboardIcon,
  DynamicFeed as MyCasesIcon,
} from "@material-ui/icons";
import Dashboard from "./pages/Dashboard";
import Cases from "./pages/Cases";
import Case from "./pages/Case";

export const routeIcons = {
  dashboard: DashboardIcon,
  mycases: MyCasesIcon,
};

export const baseRoute = "/legal";

export const menuItems = [
  {
    label: "Dashboard",
    path: "/legal",
    icon: routeIcons.dashboard,
  },
  {
    label: "Cases",
    path: `${baseRoute}/cases`,
    icon: routeIcons.mycases,
  },
];

export const routes = [
  {
    label: "Dashboard",
    path: baseRoute,
    component: Dashboard,
    exact: true,
  },
  {
    label: "Cases",
    path: `${baseRoute}/cases`,
    component: Cases,
    exact: true,
  },
  {
    label: "Dashboard",
    path: `${baseRoute}/case/:caseId`,
    component: Case,
    exact: true,
  },
];

const UNIT = "unit";
const UNITS = "units";

const getUnitEnding = (units) => (units === 1 ? UNIT : UNITS);

const minuteInSeconds = 60;
const unitisedMinute = 6;

const getUnits = (seconds) =>
  typeof seconds === "number"
    ? Math.ceil(seconds / minuteInSeconds / unitisedMinute)
    : 0;

const getUnitisedSeconds = (units) =>
  typeof units === "number" && units > 0
    ? units * unitisedMinute * minuteInSeconds
    : 0;

export { getUnits, getUnitisedSeconds, getUnitEnding };

import { alpha, makeStyles, Typography } from "@material-ui/core";
import { formatDigitalTime } from "../../../../utils/dateTimeUtils";
import { getUnitEnding, getUnits } from "../../../../utils/unitTimeUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 2),
  },
  highlightContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 2),
    background: alpha(theme.palette.secondary.light, 0.225),
    borderRadius: 6,
  },
  textBold: {
    fontSize: 15,
    wordBreak: "break-all",
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  textHighlight: {
    fontSize: 15,
    fontWeight: 600,
    wordBreak: "break-all",
    color: theme.palette.common.black,
    marginRight: theme.spacing(1),
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    wordBreak: "break-all",
    color: alpha(theme.palette.primary.main, 0.6),
    marginRight: theme.spacing(1),
  },
  unitsContrainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  unitsText: {
    fontWeight: 500,
    wordBreak: "break-all",
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
}));

const CaseTimeDurationTableItem = ({
  title,
  time,
  isTotal = false,
  isChargeable = false,
  isUnitised = false,
}) => {
  const classes = useStyles();

  const getTextStyles = () => {
    if (isTotal) {
      return classes.textBold;
    } else if (isChargeable) {
      return classes.textHighlight;
    }

    return classes.text;
  };

  const units = isUnitised ? getUnits(time) : null;

  return (
    <div
      className={isChargeable ? classes.highlightContainer : classes.container}
    >
      <Typography variant="body2" className={getTextStyles()}>
        {title}
      </Typography>
      {!isUnitised && (
        <Typography variant="body2" className={getTextStyles()}>
          {formatDigitalTime(time)}
        </Typography>
      )}
      {isUnitised && (
        <div className={classes.unitsContrainer}>
          <Typography variant="body2" className={getTextStyles()}>
            {formatDigitalTime(time)}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.unitsText}
          >
            {`(${units} ${getUnitEnding(units)})`}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CaseTimeDurationTableItem;

import React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Link,
    makeStyles, Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {riddorReportableStatus, useIncidentDetails} from "../../contexts/IncidentContext";
import { incidentTypeList } from "../../constants/incidentType";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import {grey} from "@material-ui/core/colors";
import useIncidentCategories from "../../hooks/queries/useIncidentCategories";

const useStyles = makeStyles(() => ({
  datePicker: {
    margin: 0,
  },
}));

const maximumDate = new Date().setHours(23, 59, 59);

function IncidentDetailsForm() {
  const classes = useStyles();
  const {
    incidentType,
    setIncidentType,
    incidentDate,
    setIncidentDate,
    incidentDescription,
    setIncidentDescription,
    riddorReportable,
    setRiddorReportable,
    reportingDate,
    setReportingDate,
    referenceNumber,
    setReferenceNumber,
    incidentCategoryId,
    setIncidentCategoryId,
  } = useIncidentDetails();
  
  const { data: incidentCategories } = useIncidentCategories();
    
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <KeyboardDatePicker
          required
          className={classes.datePicker}
          format="dd/MM/yyyy"
          fullWidth
          label="Incident date"
          inputVariant="outlined"
          value={incidentDate}
          onChange={setIncidentDate}
          maxDate={maximumDate}
          maxDateMessage={"Incident date must be in the past."}
        />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveSelect
          required
          fullWidth
          label="Incident type"
          options={incidentTypeList}
          optionValueKey="value"
          optionLabelKey="label"
          value={incidentType}
          onChange={(e) => setIncidentType(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveSelect
            required
            fullWidth
            label="Incident category"
            options={incidentCategories ?? []}
            optionValueKey="id"
            optionLabelKey="description"
            value={incidentCategoryId}
            onChange={(e) => setIncidentCategoryId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          variant="outlined"
          fullWidth
          label="Description"
          value={incidentDescription || ""}
          onChange={(e) => setIncidentDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">RIDDOR</Typography>
        <FormControl>
          <FormLabel id="riddor-reportable-radio-group">
            <Typography variant="subtitle2" style={{ color: grey[500] }}>
              Is the incident RIDDOR reportable? <Link color="secondary" href="https://www.hse.gov.uk/riddor/reportable-incidents.htm" target="_blank">What is RIDDOR?</Link>
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="riddor-reportable-radio-group"
            name="riddor-reportable-radio-group"
            value={riddorReportable}
            onChange={e => setRiddorReportable(parseInt(e.target.value))}
          >
            <FormControlLabel control={<Radio />} value={riddorReportableStatus.DONT_KNOW.value} label="Do not know at this time" />
            <FormControlLabel control={<Radio />} value={riddorReportableStatus.NO.value} label="No" />
            <FormControlLabel control={<Radio />} value={riddorReportableStatus.YES.value} label="Yes" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {riddorReportable === riddorReportableStatus.YES.value ?
        <>
            <Grid item xs={12}>
                <KeyboardDatePicker
                    onChange={date => setReportingDate(date)}
                    value={reportingDate}
                    fullWidth
                    inputVariant="outlined"
                    label="RIDDOR Reporting Date"
                    format="dd/MM/yyyy"
                    helperText="Date the incident was reported to HSE"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={referenceNumber}
                    onChange={e => setReferenceNumber(e.target.value)}
                    label="RIDDOR Reference Number"
                    helperText="10-digit reference number provided by HSE when incident was reported"
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
        </> :
        null
      }
      
    </Grid>
  );
}

export default IncidentDetailsForm;

import { format, parseISO, isValid } from "date-fns";
import SkeletonTableLoader from "../../common/SkeletonTableLoader";
import { useHistory } from "react-router-dom";
import { baseRoute } from "../../../routes";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    IconButton,
    makeStyles,
    MenuItem,
    Menu,
    alpha,
    Typography,
    Tooltip,
    Grid,
    Checkbox,
    InputAdornment,
    TextField,
    FormControlLabel,
    Switch,
    Divider,
    ListItemIcon,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
    Error as ErrorIcon,
    ErrorOutline as ErrorOutlineIcon,
    MoreVert as MoreVertIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlank,
    CheckBox as CheckBox,
    Search as SearchIcon,
    Clear as ClearIcon,
    DeleteOutlined as DeleteIcon,
    ArchiveOutlined as ArchiveIcon,
    EditOutlined as EditIcon,
} from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";
import { useAuth } from "../../../../contexts/authContext";
import TemplateFormAmendDialog from "./TemplateFormAmendDialog";
import TemplateFormArchiveDialog from "./TemplateFormArchiveDialog";
import TemplateFormDeleteDialog from "./TemplateFormDeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { setTablePage } from "../../../../redux/actions/tablePageActions";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";
import { sortOrderName } from "../../../constants/sortOrder";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { frequency } from "../../../constants/frequencyConstants";


const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const stdColumns = [
    { id: "templateName", label: "Template Name", isSortable: true },
    { id: "site", label: "Site Name", isSortable: true },
    { id: "responsible", label: "Responsible Name", isSortable: true },
    { id: "duedate", label: "Due Date", isSortable: true },
    { id: "CompletedActions", label: "Completed Actions" },
    { id: "LastCompletedStatus", label: "Last Completed Status" },
    { id: "LastCompleted", label: "Last Completed" },
    { id: "LastCompletedName", label: "Last Completed By" },
];

const adminColumns = [
    { id: "AdminActions", label: "Admin Actions" }
];

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(1),
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.08),
        },
    },
    tableContent: {
        marginTop: theme.spacing(2),
    },
    iconCellText: {
        marginRight: theme.spacing(1),
    },
    overdueCell: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1),
    },
    overdueCellText: {
        color: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
    },
    dueCellContainer: {
        display: "flex",
        alignItems: "start",
    },
    statusChip: {
        color: theme.palette.getContrastText(theme.palette.neutralGrey),
        borderRadius: "4px",
        backgroundColor: theme.palette.neutralGrey,
        "&.status-historic": {
            backgroundColor: "#E4DEFC",
            color: "black",
        },
        "&.status-1": {
            backgroundColor: green[400],
        },
        "&.status-2": {
            backgroundColor: red[400],
        },
    },
    searchField: {
        marginLeft: 'auto',
    },
    keyboardBtn: {
        padding: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    assignedString: {
        maxWidth: "86%",
    },
    searchTextField: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: "100%",
        '& .clearIcon': {
            visibility: 'hidden',
        },
        '&:hover .clearIcon': {
            visibility: 'visible',
        },
    },
    endAdornment: {
        paddingRight: theme.spacing(1),
    },
    tooltip: {
        margin: theme.spacing(1),
        backgroundColor: 'white',
        border: '1px solid #000',
        color: theme.palette.common.black,
        fontSize: '0.9em',
        borderRadius: 0,
        padding: theme.spacing(1),
    },
    menuDelete: {
        color: "red !important",
    },
        divider: {
        margin: theme.spacing(1, 0), 
    },
    menuItemIcon: {
        minWidth: theme.spacing(5),
},
    menuItemText: {
        marginRight: theme.spacing(5),
        fontSize: '12px' 
    },
    menuItemIconDel: {
        minWidth: theme.spacing(5),
        color:"red"
    },

}));

export function TemplateFormTable({
    data,
    isLoading,
    count,
    page,
    rows,
    moduleArea,
    tableKey,
    orderBy,
    sortBy,
    fromDate,
    toDate,
    assignees,
    filteredText,
    showHistoric,
}) {
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [formToAmend, setFormToAmend] = useState(null);
    const [isToBeDeleted, setIsToBeDeleted] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { sites, selectedAccount, selectedSite } =
        useSelector((state) => state.account);
    const { isTemplateAdminForSites } = useAuth();
    const [filteredCurrentAssignees, setFilteredCurrentAssignees] = React.useState([]);
    const notSetDropdownOption = { value: "NotSet", label: "Not Set" };
    const [searchRequested, setSeachRequested] = React.useState(false);
    const [displayHistoric, setDisplayHistoric] = useState(showHistoric || "");
    
    const [inputFromDate, setInputFromDate] = useState(fromDate || "");
    const [inputToDate, setInputToDate] = useState(toDate || "");
    const [filterText, setFilterText] = useState(filteredText);
    const { [tableKey]: formData } = useSelector((state) => state.tablePage);

    const contacts = useContactsForAppAndSite({ selectedSiteId: null });

    const filteredAsigneeOptions = contacts?.data && assignees
        ? (assignees.includes(null) || assignees.includes('') ? [notSetDropdownOption] : [])
            .concat(Object.values(contacts.data)
                .filter(user => assignees.includes(user.externalId) && user.firstName)
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(user => ({ value: user.externalId, label: `${user.firstName} ${user.lastName}` }))
            ) : [];

    const isSafetynestAmendTemplateUser = isTemplateAdminForSites(
        selectedAccount?.childExternalIds
    );

    
    const headCells = isSafetynestAmendTemplateUser
        ? stdColumns.concat(adminColumns)
        : stdColumns;

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = (e, id) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setIsMenuOpen(id);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: newPage,
                rowsPerPage: rows,
                sortBy: sortBy,
                orderBy: orderBy,
            })
        );
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10),
                sortBy: sortBy,
                orderBy: orderBy,
            })
        );
    };

    const handleSortClick = (event, sortColumn) => {
        const isAsc = sortBy === sortColumn && orderBy === sortOrderName.ASC;
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: 0,
                rowsPerPage: rows,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

    const handleClickRow = (formId) =>
        history.push(`${baseRoute}/${moduleArea}/form/${formId}`);

    const openEditFormPopup = (e, form, isToBeDeleted) => {
        e.stopPropagation();
        setFormToAmend(form);
        setIsToBeDeleted(isToBeDeleted);
        setIsEditOpen(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const openDeleteFormPopup = (e, form) => {
        e.stopPropagation();
        setFormToAmend(form);
        setIsDeleteOpen(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const openArchiveFormPopup = (e, form) => {
        e.stopPropagation();
        setFormToAmend(form);
        setIsArchiveOpen(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const Due = (dueDate) => {
        const currentDate = new Date();
        const thirtyDaysTime = new Date();
        thirtyDaysTime.setDate(currentDate.getDate() + 30);

        const parsedDueDate = parseISO(dueDate);
        const isOverdue = parsedDueDate < currentDate;
        const isComingUp = parsedDueDate >= currentDate && parsedDueDate <= thirtyDaysTime;

        return (
            <div className={classes.dueCellContainer}>
                {isOverdue ? (
                    <Tooltip title={"Overdue Item"} placement="top">
                        <ErrorIcon className={classes.overdueCell} fontSize="small" />
                    </Tooltip>
                ) : isComingUp ? (
                    <Tooltip title={"Item is Coming up"} placement="top">
                        <ErrorOutlineIcon className={classes.iconCellText} color="primary" fontSize="small" />
                    </Tooltip>
                ) : null}
                <Typography variant="body2">
                    {format(parsedDueDate, "dd/MM/yyyy")}
                </Typography>
            </div>
        );
    };

    useEffect(() => {
        dispatch(
            setTablePage({
                ...formData,
                    key: tableKey,
                    page: 0,
                    rowsPerPage: rows,
                })
        );
    }, [selectedSite]);

    function handleCurrentAssigneesChange(event, inputs) {
        setFilteredCurrentAssignees(inputs);
        if (inputs.length == 0) {
            dispatch(
                setTablePage({
                    ...formData,
                    key: tableKey,
                    page: 0,
                    rowsPerPage: rows,
                    assignees: [],
                }))
        }
    }

    const handleConfirmedAssigneesChange = (event, inputs) => {
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: 0,
                rowsPerPage: rows,
                assignees: filteredCurrentAssignees,
            })
        );
    }

    const handleToDateChange = (date) => {
        setInputToDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setTablePage({
                    ...formData,
                    key: tableKey,
                    page: 0,
                    rowsPerPage: rows,
                    toDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    const handleFromDateChange = (date) => {
        setInputFromDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setTablePage({
                    ...formData,
                    key: tableKey,
                    page: 0,
                    rowsPerPage: rows,
                    fromDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    function handleSearchChange(e) {
        setSeachRequested(false);
        onFilteredTextChange(e.target.value)
    }

    const handleSearchClick = () => {
        setSeachRequested(true);
        onFilteredTextSearch();
    };

    const showFilteredTextError = () => {
        return (filterText && filterText.length > 0 && filterText.length < 4 && searchRequested)
    }

    const onFilteredTextChange = (textString) => {
        setFilterText(textString);
    };

    const onFilteredTextSearch = () => {
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: 0,
                rowsPerPage: rows,
                filteredText: filterText
            })
        );
    };

    const handleFilteredTextClear = () => {
        setFilterText('');
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: 0,
                rowsPerPage: rows,
                filteredText: ''
            })
        );
    };

    const handleShowHistoricChange = (event) => {
        setDisplayHistoric(event.target.checked);
        dispatch(
            setTablePage({
                ...formData,
                key: tableKey,
                page: 0,
                showHistoric: event.target.checked,
            })
        );
    };

    function handleKeyDown(event) {

        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    const handleSnackbarAction = (switchVal) => {
        handleShowHistoricChange({ target: { checked: switchVal } });
    };

    if (isLoading)
        return <SkeletonTableLoader columns={headCells.map(column => column.id)} numberOfRows={4} />;

    return (
        <div className={classes.tableWrapper}>
            <Grid container spacing={1} >
                <Grid item xs={12} md={6} xl={3} className={classes.toolbar}>
                    <Autocomplete
                        multiple
                        value={filteredCurrentAssignees && filteredCurrentAssignees.length > 0 ? filteredCurrentAssignees :
                            (formData.assignees && formData.assignees.length > 0 ? formData.assignees : [])}
                        onChange={handleCurrentAssigneesChange}
                        onBlur={handleConfirmedAssigneesChange}
                        options={filteredAsigneeOptions}
                        disableCloseOnSelect
                        clearOnBlur
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => {
                            return option.label === value.label;
                        }}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    className={classes.checkbox}
                                    icon={uncheckedIcon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                {option.label}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                label="Responsible Person(s)"
                                fullWidth
                            />
                        )}
                        renderTags={selected => {
                            console.log("selected = ", selected.filter(item => item && item.label));
                            let renderTagsValue = selected.filter(item => item && item.label)
                                .map(function (elem) {
                                    return elem.label;
                                })
                                .join(", ");
                            return (
                                <Typography className={classes.assignedString}
                                    noWrap={true}
                                    color="textPrimary"
                                >
                                    {renderTagsValue}
                                </Typography>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={1} className={classes.toolbar}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            fullWidth
                            size="small"
                            inputVariant="outlined"
                            label="From date"
                            format="dd/MM/yyyy"
                            value={inputFromDate || null}
                            onChange={(date) => handleFromDateChange(date)}
                            maxDate={inputToDate}
                            KeyboardButtonProps={{ className: classes.keyboardBtn }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6} xl={1} className={classes.toolbar}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            fullWidth
                            size="small"
                            inputVariant="outlined"
                            label="To date"
                            format="dd/MM/yyyy"
                            value={inputToDate || null}
                            onChange={(date) => handleToDateChange(date)}
                            minDate={inputFromDate}
                            KeyboardButtonProps={{ className: classes.keyboardBtn }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6} xl={3} className={classes.toolbar}>
                <FormControlLabel
                    control={<Switch
                          checked={displayHistoric}
                          onChange={handleShowHistoricChange} 
                        />}
                    label="Show Archived Forms"
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={2} className={classes.searchField}>
                    <TextField
                        className={classes.searchTextField}
                        label="Search Description"
                        variant="outlined"
                        size="small"
                        value={filterText}
                        onChange={handleSearchChange}
                        helperText='Please enter at least 4 character to search on'
                        error={showFilteredTextError()}
                        FormHelperTextProps={{ hidden: !showFilteredTextError() }}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {filterText && (
                                        <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                            <IconButton className={classes.iconBtn}>
                                                <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                    onClick={handleFilteredTextClear}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Search" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                        <IconButton className={classes.iconBtn}>
                                            <SearchIcon
                                                onClick={handleSearchClick}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                            className: classes.endAdornment
                        }}
                    />
                </Grid>
            </Grid>

            <TableContainer className={classes.tableContent}>
                <Table size="small">
                    <EnhancedTableHead
                        headCells={headCells}
                        sortColumn={sortBy}
                        sortDirection={orderBy}
                        onRequestSort={handleSortClick}
                    />
                    <TableBody>
                        {data?.length ? (
                            data.map((form) => (
                                <TableRow
                                    className={classes.tableRow}
                                    key={form.id}
                                    onClick={() => handleClickRow(form.id)}
                                >
                                    <TableCell>{form.templateName}</TableCell>
                                    <TableCell>
                                        {sites[form.siteExternalId]?.name ?? "Unknown"}
                                    </TableCell>
                                    <TableCell>
                                        {contacts?.data[form.assignedEmployeeExternalId] ?
                                            `${contacts?.data[form.assignedEmployeeExternalId]
                                                ?.firstName
                                            } ${contacts?.data[form.assignedEmployeeExternalId]
                                                ?.lastName
                                            }` : ""}
                                    </TableCell>
                                    <TableCell>{(!form.templateIsEnabled && form.templateIsDri || !form.isActive) ? <Chip
                                        className={clsx(classes.statusChip, `status-historic`)}
                                        size="small"
                                        label="Archived"
                                    /> : Due(form.dueDate)}</TableCell>
                                    <TableCell>
                                        {form.lastCompletedActionsSummary}
                                    </TableCell>
                                    <TableCell>
                                        {form.lastStatus && <Chip
                                            size="small"
                                            label={form.lastStatusDescription}
                                            className={clsx(classes.statusChip, `status-${form.lastStatus}`)}
                                        />
                                        }
                                    </TableCell>
                                    <TableCell>{form.lastCompleteDate && format(parseISO(form.lastCompleteDate), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>
                                        {contacts?.data[form.lastAssignedEmployeeExternalId] ?
                                            `${contacts?.data[form.lastAssignedEmployeeExternalId]
                                                ?.firstName
                                            } ${contacts?.data[form.lastAssignedEmployeeExternalId]
                                                ?.lastName
                                            }` : ""}
                                    </TableCell>
                                    {isSafetynestAmendTemplateUser && (
                                        <TableCell>
                                            <IconButton onClick={(e) => handleClick(e, form.id)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                keepMounted
                                                open={isMenuOpen === form.id}
                                                onClose={handleClose}
                                                anchorEl={anchorEl}
                                            >
                                                {form.frequency !== frequency.NONE && form.isActive && (
                                                    <MenuItem onClick={(e) => openEditFormPopup(e, form, false)}>
                                                        <ListItemIcon className={classes.menuItemIcon} >
                                                            <EditIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography className={classes.menuItemText}>
                                                            Edit
                                                        </Typography>
                                                    </MenuItem>
                                                )}
                                                {form.frequency !== frequency.NONE && (
                                                    <MenuItem onClick={(e) => openArchiveFormPopup(e, form)}>
                                                        <ListItemIcon className={classes.menuItemIcon} >
                                                            <ArchiveIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography className={classes.menuItemText}>
                                                            {form.isActive ? "Archive" : "Unarchive"}
                                                        </Typography>
                                                    </MenuItem>
                                                )}
                                                {form.frequency !== frequency.NONE && (
                                                    <Divider className={classes.divider} />
                                                )}
                                                <MenuItem className={classes.menuDelete} onClick={(e) => openDeleteFormPopup(e, form)}>
                                                    <ListItemIcon className={classes.menuItemIconDel}  >
                                                        <DeleteIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography className={classes.menuItemText}>
                                                        Delete
                                                    </Typography>
                                                </MenuItem>
                                            </Menu>

                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <>
                                <TableRow>
                                    <TableCell colSpan={headCells.length}>
                                        No data to display
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count || 0}
                    rowsPerPage={rows}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <TemplateFormAmendDialog
                isOpen={isEditOpen}
                setIsOpen={setIsEditOpen}
                form={formToAmend}
                isToBeDeleted={isToBeDeleted}
                page={page}
                rows={rows}
                moduleArea={moduleArea}
            />
            <TemplateFormDeleteDialog
                isOpen={isDeleteOpen}
                setIsOpen={setIsDeleteOpen}
                form={formToAmend}
                page={page}
                rows={rows}
                moduleArea={moduleArea}
            />
            <TemplateFormArchiveDialog
                isOpen={isArchiveOpen}
                setIsOpen={setIsArchiveOpen}
                form={formToAmend}
                page={page}
                rows={rows}
                moduleArea={moduleArea}
                handleSnackbarAction={handleSnackbarAction}
            />

            
        </div>
    );
}

const EMAIL = "Email";
const NOTE = "Note";
const INTERNAL_NOTE = "Internal Note";
const CALL = "Call";
const MISC = "Misc";
const DOCUMENT = "Document";
const ADVISER = "Adviser";
const DRAFTING = "Drafting";
const OUTCOME = "Outcome";

export {
  EMAIL,
  NOTE,
  INTERNAL_NOTE,
  CALL,
  MISC,
  DOCUMENT,
  ADVISER,
  DRAFTING,
  OUTCOME,
};

import {
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import chipStyles from "../../theme/chips";
import { formatShortDate } from "../../utils/dateTimeUtils";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
  salesforceWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  infoContainer: {
    margin: theme.spacing(3, 0),
  },
  infoValue: {
    fontWeight: 600,
  },
}));

const OrganisationCard = ({ account }) => {
  const classes = useStyles();
  const chipClasses = chipStyles();

  const tableData = {
    Client: account.name,
    Address: account.address,
    Sector: account.sector,
    "Service Type": account.serviceType,
    "Client Manager": account.clientManager,
  };

  if (account.contractEnd) {
    tableData["Contract End"] = formatShortDate(account.contractEnd);
  }

  const table = Object.keys(tableData).map((key, index) => (
    <Grid key={index} container className={classes.infoContainer}>
      <Grid item xs={6} md={12} lg={6}>
        <Typography variant="body2">{key}</Typography>
      </Grid>
      <Grid item xs={6} md={12} lg={6}>
        <Typography
          style={{ whiteSpace: key === "Address" ? "pre-wrap" : "initial" }}
          variant="subtitle2"
          className={classes.infoValue}
        >
          {tableData[key]}
        </Typography>
      </Grid>
    </Grid>
  ));

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Account
            </Typography>
          </Grid>
          {account.isDeleted && (
            <Grid item>
              <Typography variant="h6" gutterBottom>
                *Deleted*
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Chip
              size="small"
              className={
                account.status === "Active"
                  ? chipClasses.green
                  : account.status === "Client"
                  ? chipClasses.green
                  : account.status === "Partner Member"
                  ? chipClasses.green
                  : account.status === "Pending Partner Member"
                  ? chipClasses.amber
                  : chipClasses.root
              }
              label={
                account.status === "Client"
                  ? "Active"
                  : account.status === "Partner Member"
                  ? "Active"
                  : account.status === "Pending Partner Member"
                  ? "Pending"
                  : account.status
                  ? account.status
                  : "N/A"
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7} sm={6} md={12}>
            {table}
          </Grid>
        </Grid>
        <div className={classes.salesforceWrapper}>
          <img
            src="/images/brands/salesforce.png"
            height="42px"
            width="60px"
            alt="salesforce"
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default OrganisationCard;

import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    makeStyles,
    IconButton,
    alpha,
    Chip,
} from "@material-ui/core";
import { InfoOutlined, AttachFile } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { nanoid } from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "initial",
            },
        },
        title: { fontSize: 18, fontWeight: 600 },
        formItem: {
            margin: theme.spacing(1, 0),
        },
        actionContainer: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(2, 3),
        },
        actionBtns: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        infoContainer: {
            display: "flex",
            alignItems: "start",
            fontSize: 13,
            color: alpha(theme.palette['errorRed'], 0.75),
        },
        infoIcon: {
            marginRight: theme.spacing(1),
            fontSize: 16,
        },
        saveBtn: {
            whiteSpace: "nowrap",
            padding: theme.spacing(1, 3),
            marginLeft: theme.spacing(2),
        },
        iconButton: {
            padding: theme.spacing(1),
            color: theme.palette.primary.main,
            fontSize: 40,
        },
        attachmentsContainer: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
            border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
            borderRadius: 4,
        },
        attachmentsTitle: {
            fontSize: 13,
            fontWeight: 600,
        },
        chipContainer: {
            display: "flex",
            flexWrap: "wrap",
            marginTop: theme.spacing(2),
        },
        chipWrapper: {
            margin: theme.spacing(0, 1, 1, 0),
        },
        chip: {
            color: theme.palette.primary.main,
        },
        chipLabel: {
            fontSize: 12,
        },
    };
});

const CustomerNoteDialog = ({
    open,
    existingSubject,
    existingMessage,
    attachments,
    onClose,
    onSave,
}) => {
    const classes = useStyles();
    const [savingNote, setSavingNote] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [subject, setSubject] = useState(existingSubject ?? "");
    const [message, setMessage] = useState(existingMessage ?? "");
    const [files, setFiles] = useState([]);
    const [existingAttachments, setExistingAttachments] = useState(
        attachments ?? []
    );
    const [existingFilesToDelete, setExistingFilesToDelete] = useState([]);
    const [formErrors, setFormErrors] = useState({
        subject: false,
        message: false,
    });

    const handleFormReset = () => {
        setSubject("");
        setMessage("");
        setFormErrors({
            subject: false,
            message: false,
        });
        setFiles([]);
        setExistingAttachments([]);
    };

    const handleFormValidation = () => {
        let formIsValid = true;
        let errors = { ...formErrors };
        if (subject === "") {
            formIsValid = false;
            errors = { ...errors, subject: true };
        }

        if (message === "") {
            formIsValid = false;
            errors = { ...errors, message: true };
        }

        if (!formIsValid) {
            setFormErrors({ ...errors });
        }
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleFormValidation()) {
            enqueueSnackbar("Please ensure all required form fields have been filled in.", { variant: "error" });
            return;
        }
        setSavingNote(true);
        await onSave(subject, message, files, existingFilesToDelete);
        handleFormReset();
    };

    const handleFileUpload = (e) => {
        const newFilesArray = Array.from(e.target.files).map((file) => ({
            id: nanoid(),
            file,
        })) ?? [];

        setFiles(files.concat(newFilesArray));
    };

    const handleNewFileDelete = (fileId) => {
        setFiles(files.filter((file) => file.id !== fileId));
    };

    const handleExistingFileDelete = (fileReference) => {
        setExistingFilesToDelete([...existingFilesToDelete, fileReference]);
        setExistingAttachments(
            existingAttachments.filter(
                (attachment) => attachment.reference !== fileReference
            )
        );
    };

    const showAttachmentsContainer =
        files?.length > 0 || existingAttachments?.length > 0;

    return (
        <Dialog className={classes.root} onClose={() => onClose(false)} open={open}>
            <form onSubmit={handleSubmit} noValidate>
                <DialogTitle>
                    <Typography variant="body1" color="primary" className={classes.title}>
                        New Note
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        className={classes.formItem}
                        fullWidth
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        label="Subject"
                        multiline
                        disabled={savingNote}
                        variant="outlined"
                        inputProps={{ maxLength: 255 }}
                        error={formErrors.subject && !subject}
                        helperText={formErrors.subject && !subject ? "Please enter a subject" : ""}
                        required
                    />
                    <TextField
                        className={classes.formItem}
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        disabled={savingNote}
                        label="Note"
                        multiline
                        rows={8}
                        variant="outlined"
                        error={formErrors.message && !message}
                        helperText={formErrors.message && !message ? "Please enter a note" : ""}
                        required
                    />
                    {showAttachmentsContainer && (
                        <div className={classes.attachmentsContainer}>
                            <Typography variant="body2" className={classes.attachmentsTitle}>Attachments</Typography>
                            <div className={classes.chipContainer}>
                                {existingAttachments.length > 0 &&
                                    existingAttachments.map((attachment) => (
                                        <div
                                            className={classes.chipWrapper}
                                            key={attachment.filename}
                                        >
                                            <Chip
                                                className={classes.chip}
                                                label={
                                                    <Typography className={classes.chipLabel}>
                                                        {attachment.filename}
                                                    </Typography>
                                                }
                                                size="small"
                                                onDelete={() => !savingNote && handleExistingFileDelete(attachment.reference)}
                                            />
                                        </div>
                                    ))}
                                {files?.length > 0 &&
                                    files.map((file) => (
                                        <div className={classes.chipWrapper} key={file.id}>
                                            <Chip
                                                className={classes.chip}
                                                label={
                                                    <Typography className={classes.chipLabel}>
                                                        {file.file.name}
                                                    </Typography>
                                                }
                                                size="small"
                                                onDelete={() => !savingNote && handleNewFileDelete(file.id)}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </DialogContent>

                <DialogActions className={classes.actionContainer}>
                    <div className={classes.infoContainer}>
                        <InfoOutlined fontSize="inherit" className={classes.infoIcon} />
                        <Typography variant="inherit">
                            Your legal adviser will not be notified when a note is added.
                            'Seek Advice' to request a review.
                        </Typography>
                    </div>
                    <div className={classes.actionBtns}>
                        <IconButton
                            disabled={savingNote}
                            color="primary"
                            component="label"
                            onChange={handleFileUpload}
                        >
                            <input type="file" multiple hidden />
                            <AttachFile />
                        </IconButton>
                        <Button
                            disabled={savingNote}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            className={classes.saveBtn}
                        >
                            Save Note
                        </Button>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CustomerNoteDialog;

import React from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import CardBase from "../../components/cards/CardBase";

const MilestoneDocumentsCard = ({ documents, setDocumentGuid }) => {
  return (
    <CardBase title="Milestone Documents">
      {documents?.length === 0 ? (
        <Typography>No documents.</Typography>
      ) : (
        <List component="nav" dense={true}>
          {documents.map((d) => (
            <ListItem
              button
              key={d.id}
              onClick={() => setDocumentGuid(d.identifier)}
            >
              <ListItemText primary={d.name} />
            </ListItem>
          ))}
        </List>
      )}
    </CardBase>
  );
};

export default MilestoneDocumentsCard;

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { NOTE, MISC, EMAIL } from "../../constants/caseActivityTypes";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  dialogText: {
    color: "black",
  },
  cursorStylePointer: {
    cursor: "pointer",
  },
  searchResultWrapper: {
    marginLeft: theme.spacing(2),
    whiteSpace: "pre-line",
  },
  boldText: {
    fontWeight: "bold",
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

const CaseActivityPreviewDialog = ({
  open,
  caseActivity,
  onClose,
  openDocument,
}) => {
  const classes = useStyles();

  if (!open || !caseActivity) return null;

  const noteText =
    caseActivity.itemType === NOTE && !caseActivity.htmlNote
      ? `<div>
                      <div style="font-weight: bold;">${
                        caseActivity.issue && "Issue"
                      }</div>
                      <div>${caseActivity.issue}</div>
                      <div style="font-weight: bold;">${
                        caseActivity.advice && "Advice"
                      }</div>
                      <div>${caseActivity.advice}</div>
                      <div style="font-weight: bold;">${
                        caseActivity.action && "Action"
                      }</div>
                      <div>${caseActivity.action}</div>
                  </div>`
      : caseActivity.htmlNote
      ? caseActivity.htmlNote
      : caseActivity.itemType === MISC
      ? `<div>
                                  <div style="font-weight: bold;">${caseActivity.miscActivityTypeDescription}</div>
                                  <div>${caseActivity.content}</div>
                              </div>`
      : caseActivity.content;

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          {caseActivity.attachments &&
            caseActivity.attachments.map((a) => (
              <Typography key={a.reference} component="span">
                <AttachFileIcon fontSize="small" />
                <Tooltip title={a.filename}>
                  <Link
                    className={classes.cursorStylePointer}
                    underline="hover"
                    onClick={() => openDocument(a)}
                  >
                    {a.filename}
                  </Link>
                </Tooltip>
              </Typography>
            ))}
          {caseActivity.itemType === EMAIL && (
            <div className={classes.dialogContainer}>
              <div>
                <span className={classes.boldText}>From: </span>
                {caseActivity.fromAddress
                  ? caseActivity.fromAddress
                  : caseActivity.person}
              </div>
              <div>
                <span className={classes.boldText}>Sent: </span>
                {format(new Date(caseActivity.eventTime), "dd/MM/yyyy")}
              </div>
              <div>
                <span className={classes.boldText}>To: </span>
                {caseActivity.toAddress}
              </div>
              <div>
                <span className={classes.boldText}>Subject: </span>
                {caseActivity.subject}
              </div>
            </div>
          )}
          <Typography
            className={classes.searchResultWrapper}
            component="span"
            dangerouslySetInnerHTML={{
              __html: noteText,
            }}
          ></Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CaseActivityPreviewDialog;

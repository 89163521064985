import React from "react";
import FileViewer from "react-file-viewer";
import { Box, Dialog, DialogTitle, Typography, IconButton } from "@material-ui/core";
import "../css/fileViewer.css"
import { CloudDownload } from '@material-ui/icons';
import caseService from "../services/caseService";

const FileViewerModal = ({
  open,
  onClose,
  fileType,
  filePath,
  fileName,
  caseId,
  azureGuid,
}) => {

    const downloadDocument = async () => {
        const response = await caseService.fetchDocument(azureGuid, 'arraybuffer')
        let blob = new Blob([response], { type: fileType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    return (
            <Dialog
                alignItems= "center"
                className="file-modal"
                onClose={onClose}
                open={open}
                fullWidth
                maxWidth={"lg"}
            >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Viewing document - {fileName} - Case {caseId}</Typography>
                    <IconButton onClick={() => downloadDocument()}><CloudDownload /></IconButton>
                </Box>
            </DialogTitle>
                <FileViewer fileType={fileType.toLowerCase()} filePath={filePath} />
            </Dialog>
  );
};

export default FileViewerModal;

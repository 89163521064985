import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import caseService from "../../services/caseService";

const useETCaseDetails = (caseId) =>
  useQuery(
    [queryKeys.eTCaseDetails, caseId],
    () => caseService.fetchETCaseDetails(caseId),
    {
      enabled: !!caseId,
    }
  );

export default useETCaseDetails;

import { Skeleton } from "@material-ui/lab";
import { Grid, makeStyles } from "@material-ui/core";
import layoutConstants from "../../constants/layoutConstants";
import React from "react";

const useStyles = makeStyles(() => ({
  skeletonLoader: {
    borderRadius: '8px'
  }
}))

export function DashboardHeadlineLoader({headlines}) {
    const classes = useStyles()
    const headlineArray = Array.apply(null, Array(headlines))
    
    return (
        <>
            {headlineArray?.map(headline => !headline && <Grid item><Skeleton className={classes.skeletonLoader} variant="rectangular" height={layoutConstants.headlineTileHeight} width={layoutConstants.headlineTileWidth} /></Grid>)}
        </>
    )
}
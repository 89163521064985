import React from "react";
import { Switch } from "react-router-dom";
import { useTracking } from "react-tracking";
import AuthLayout from "../layouts/AuthLayout";
import trackingService from "../services/trackingService";
import trackingConstants from "../constants/trackingConstants";
import { protectedRoutes } from "../routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider as VendingMachine } from "notistack";
import RouteMapper from "./RouteMapper";
import TutorialTracker from "./TutorialTracker";
import CookieJar from "./CookieJar";
import { useAuth } from "../contexts/authContext";

const AuthenticatedApp = () => {
  const { impersonatingUser } = useAuth();
  const { Track } = useTracking(
    {
      app: trackingConstants.appName,
      isImpersonationSession: !!impersonatingUser,
    },
    {
      dispatch: trackingService.sendTrackingData,
      process: (ownTrackingData) =>
        ownTrackingData.page
          ? { event: trackingConstants.events.pageView }
          : null,
    }
  );

  return (
    <Track>
      <AuthLayout>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <VendingMachine maxSnack={3}>
            <TutorialTracker />
            <CookieJar />
            <Switch>
              <RouteMapper routes={protectedRoutes} />
            </Switch>
          </VendingMachine>
        </MuiPickersUtilsProvider>
      </AuthLayout>
    </Track>
  );
};

export default AuthenticatedApp;

import { Grid } from "@material-ui/core";
import useContactCasesForHeadlines from "../hooks/queries/useContactCasesForHeadlines";
import { Alert } from "@material-ui/lab";
import { DashboardHeadlineLoader } from "./SkeletonLoaders";
import { useSelector } from "react-redux";
import { HeadlineTile } from "./HeadlineTile";
import { useDashboardFilter } from "../contexts/dashboardContext";
import React from "react";

const headlineCards = [
  {
    dataField: "openCases",
    title: "Open Cases",
  },
  {
    dataField: "newCases",
    title: "New Cases",
  },
  {
    dataField: "calls",
    title: "Calls",
  },
  {
    dataField: "emails",
    title: "Emails",
  },
  {
    dataField: "etClaims",
    title: "ET Claims",
  },
  {
    dataField: "highRiskCases",
    title: "High Risk Cases",
  },
];

export default function DashboardHeadlines() {
  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );
  const { fromDate, toDate, displayAll } = useDashboardFilter();

  const { data, isError, isLoading, error } = useContactCasesForHeadlines({
    fromDate,
    toDate,
    displayAll,
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });

  if (isError)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  return (
    <Grid container justifyContent="center" spacing={2}>
      {isLoading ? (
        <DashboardHeadlineLoader headlines={headlineCards.length} />
      ) : (
        <>
          {headlineCards.map((headline) => {
            return (
              <Grid item key={headline.dataField}>
                <HeadlineTile
                  data={data?.[headline.dataField]}
                  title={headline.title}
                />
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
}

import { useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import useCaseActivities from "../hooks/queries/useCaseActivities";
import documentService from "../services/documentService";
import CaseActivityPreviewDialog from "./dialogs/CaseActivityPreviewDialog";
import CaseHistoryComponent from "./CaseHistoryComponent";
import reactQueryUtils from "../../utils/reactQueryUtils";
import caseService from "../services/caseService";
import CustomerNoteDialog from "./dialogs/CustomerNoteDialog";
import CaseActivityMenu from "./CaseActivityMenu";
import queryKeys from "../../constants/queryKeys";

const VALUES_PER_PAGE = 10;

const CaseHistoryCard = ({ caseId, contractId, isUnitisedTime, openFileViewer }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [pageNumber, setPageNumber] = useState(1);
  const [previewCaseActivity, setPreviewCaseActivity] = useState(null);
  const [openNewNoteDialog, setOpenNewNoteDialog] = useState(false);
  const [contextAnchorEl, setContextAnchorEl] = useState(null);
  const [contextEvent, setContextEvent] = useState(null);

  const { data, isLoading, error } = useCaseActivities({
    caseId,
    pageSize: VALUES_PER_PAGE,
    pageNum: pageNumber,
  });

  const { caseActivities, count = 0 } = data || {};

  const numberOfPages = useMemo(() => {
    if (count) return Math.ceil(count / VALUES_PER_PAGE);
  }, [count]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
    window.scrollTo(0, 0);
  };

  const handleOpenContextMenu = (e, event) => {
    setContextEvent(e);
    setContextAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setContextEvent(null);
    setContextAnchorEl(null);
  };

  const handleEditNoteClick = () => {
    setOpenNewNoteDialog(true);
  };

  const handleClickAttachment = async (file) => {
    const { reference, filename, contentType, extension } = file;

    try {
      const previewPath = await documentService.retrieveDocumentPreviewPath({
        reference,
        contentType,
      });

      openFileViewer({
        type: extension,
        path: previewPath,
        name: filename,
        azureGuid: reference,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePreviewClick = (caseEvent) => setPreviewCaseActivity(caseEvent);

  const handlePreviewClose = () => setPreviewCaseActivity(null);

  const handleCustomerNoteUpdate = async (
    subject,
    message,
    newAttachments,
    filesToDelete
  ) => {
    if (!contextEvent) return;

    try {
      await caseService.updateCustomerNote({ caseActivityId: contextEvent.caseActivityId, subject, message, newAttachments, filesToDelete });
      enqueueSnackbar("Your note has been updated", {
        variant: "success",
      });

      reactQueryUtils.client.invalidateQueries([
        queryKeys.caseActivities,
        caseId,
        10,
        1,
      ]);
    } catch (e) {
      enqueueSnackbar("Updating customer note failed", {
        variant: "error",
      });
      console.error(e);
    } finally {
      setOpenNewNoteDialog(false);
      handleContextMenuClose();
    }
  };

  const handleCustomerNoteAdd = async (subject, message, attachments) => {
    if (!contractId) return;

    try {
      await caseService.addCustomerNote({ caseId, contractId, subject, message, attachments });

      enqueueSnackbar("Your note has been added!", {
        variant: "success",
      });

      reactQueryUtils.client.invalidateQueries([
        queryKeys.caseActivities,
        caseId,
        10,
        1,
      ]);
    } catch (e) {
      enqueueSnackbar("Adding customer note failed", {
        variant: "error",
      });
      console.error(e);
    } finally {
      setOpenNewNoteDialog(false);
      handleContextMenuClose();
    }
  };

  return (
    <>
      <CaseHistoryComponent
        caseActivities={caseActivities}
        count={count}
        valuesPerPage={VALUES_PER_PAGE}
        numberOfPages={numberOfPages}
        pageNumber={pageNumber}
        isLoading={isLoading}
        isUnitisedTime={isUnitisedTime}
        error={error}
        onPreviewClick={handlePreviewClick}
        onPageChange={handlePageChange}
        onNewNoteClick={setOpenNewNoteDialog}
        onSideMenuClick={handleOpenContextMenu}
      />
      <CaseActivityMenu
        contextEvent={contextEvent}
        contextAnchorEl={contextAnchorEl}
        onContextMenuClose={handleContextMenuClose}
        onEditClick={handleEditNoteClick}
      />
      {!!previewCaseActivity && (
        <CaseActivityPreviewDialog
          open={!!previewCaseActivity}
          caseActivity={previewCaseActivity}
          onClose={handlePreviewClose}
          openDocument={handleClickAttachment}
        />
      )}
      {!!openNewNoteDialog && (
        <CustomerNoteDialog
          open={openNewNoteDialog}
          existingSubject={contextEvent?.activityDescription}
          existingMessage={contextEvent?.content}
          attachments={contextEvent?.attachments}
          onClose={setOpenNewNoteDialog}
          onSave={
            !contextEvent ? handleCustomerNoteAdd : handleCustomerNoteUpdate
          }
        />
      )}
    </>
  );
};

export default CaseHistoryCard;

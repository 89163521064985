import React from "react";
import CardBase from "../../components/cards/CardBase";
import { Chip, Typography, Box, makeStyles, Tooltip } from "@material-ui/core";
import chipStyles from "../../theme/chips";
import { HomeWork, BusinessCenter, Business, Person } from "@material-ui/icons";
import useAccountDetails from "../hooks/queries/useAccountDetails";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { format } from "date-fns";

const status = {
  ACTIVE: "Active",
  CLIENT: "Client",
  PARTNERMEMBER: "Partner Member",
  PENDINGPARTNERMEMBER: "Pending Partner Member",
  PENDING: "Pending",
  NA: "N/A",
};

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
  cardContent: {
    marginBottom: "6px",
  },
  custDetail: {
    padding: theme.spacing(2),
  },
  treeLabel: {
    color: "black",
    cursor: "crosshair",
  },
  treeLabelNormal: {
    backgroundColor: "white",
    color: "black",
    cursor: "pointer",
  },
  centeredCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }
}));

const AccountDetailsCard = () => {
  const chipClasses = chipStyles();
  const classes = useStyles();

  const { selectedSite } = useSelector(
    (state) => state.account
  );

  const accountExternalId = selectedSite?.externalId

  const { data, isLoading, error, isFetching } = useAccountDetails({ accountExternalId });

  const cardContent = () => {
    if (data)
      return (
        <React.Fragment>
          <Box display="flex">
            <Box flexGrow={1} alignItems="center">
              <Typography variant="h6" mb={6}>
                Account Details
              </Typography>
            </Box>
            <Box>
              <Chip
                className={
                  data.status === status.ACTIVE ||
                  data.status === status.CLIENT ||
                  data.status === status.PARTNERMEMBER
                    ? clsx(chipClasses.base, chipClasses.green)
                    : data.status === status.PENDINGPARTNERMEMBER
                    ? clsx(chipClasses.base, chipClasses.amber)
                    : clsx(chipClasses.base, chipClasses.root)
                }
                label={
                  data.status === status.CLIENT
                    ? status.ACTIVE
                    : data.status === status.PARTNERMEMBER
                    ? status.ACTIVE
                    : data.status === status.PENDINGPARTNERMEMBER
                    ? status.PENDING
                    : data.status
                    ? data.status
                    : status.NA
                }
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.custDetail} variant="h6">
              {data.name}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <HomeWork fontSize="large" />
            </Box>
            <Box flexGrow={1} ml={3}>
              <Typography variant="body1">{data.address}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mt={3}>
            <Box>
              <BusinessCenter fontSize="large" />
            </Box>
            <Box flexGrow={1} ml={3}>
              <Typography variant="body1">{data.sector}</Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              className={classes.empCounter}
              alignItems="center"
            >
              <Box>
                <Person fontSize="large" />
              </Box>
              <Box flexGrow={1} ml={3} mr={6}>
                {data.numberOfEmployees ? (
                  <Tooltip
                    title={
                      "Employee count on salesforce (last updated - " +
                      format(new Date(data.lastUpdated), "dd/MM/yyyy") +
                      ")"
                    }
                  >
                    <Typography variant="body1">
                      {data.numberOfEmployees}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="body1">N/A</Typography>
                )}
              </Box>
            </Box>
            {data.numberOfSites > 1 ? (
              <Box
                className={classes.addPointer}
                display="flex"
                alignItems="center"
              >
                <Box>
                  <Business fontSize="large" />
                </Box>
                <Box flexGrow={1} ml={3}>
                  <Typography variant="body1">{data.numberOfSites}</Typography>
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" mt={3} ml={6}>
                <Box>
                  <Business fontSize="large" />
                </Box>
                <Box flexGrow={1} ml={3}>
                  <Typography variant="body1">{data.numberOfSites}</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </React.Fragment>
      );
    return <Typography>No Account Data</Typography>;
  };

  if (!accountExternalId)
    return (
      <CardBase
        fullHeight
      >
        <div className={classes.centeredCard}>
          <Typography>
            Please select a site
          </Typography>
        </div>
      </CardBase>
    )

  return (
    <CardBase
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      fullHeight
    >
      {cardContent()}
    </CardBase>
  );
};

export default AccountDetailsCard;

import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: theme.spacing(3),
  },
  textField: {
    width: "100%",
    marginRight: theme.spacing(3),
  },
  colSpan: {
    gridColumn: "1 / span 4",
  },
  multilineTypography: {
    whiteSpace: "pre-line",
  },
  rightHalf: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
  },
  leftHalf: {
    minWidth: "max-content",
  },
  fullGrid: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    gridColumn: "1 / span 4",
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    fontWeight: 600,
  },
  loadingErrorContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const InsuranceDetailsCard = ({ etClaim, isLoading, isError, error }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className={classes.accordionDetails}>
          <div className={classes.fullGrid}>
            <div className={classes.leftHalf}>
              <Typography variant="h6">Insurance Details</Typography>
            </div>
          </div>
          {isLoading && (
            <>
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton
                height={80}
                variant="rect"
                className={classes.colSpan}
              />
            </>
          )}
          {isError && (
            <div className={classes.loadingErrorContainer}>
              <Alert variant="outlined" severity="error">
                {error?.message || "Unable to load ET case details"}
              </Alert>
            </div>
          )}
          {!isLoading && !isError && (
            <>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Insurer
                </Typography>
                <Typography variant="body2">
                  {etClaim?.insurerName ?? "-"}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Case Covered
                </Typography>
                <Typography variant="body2">
                  {etClaim?.isCaseCovered
                    ? "Yes"
                    : etClaim?.isCaseCovered === false
                    ? "No"
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Insurance Reference
                </Typography>
                <Typography variant="body2">
                  {etClaim?.insuranceReference
                    ? etClaim?.insuranceReference
                    : "-"}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Hours Authorised
                </Typography>
                <Typography variant="body2">
                  {etClaim?.hoursAuthorised ?? "0"}
                </Typography>
              </div>
              <div className={classes.colSpan}>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Notes
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.multilineTypography}
                >
                  {etClaim?.notes ? etClaim?.notes : "-"}
                </Typography>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default InsuranceDetailsCard;

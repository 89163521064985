import {
  Typography,
  makeStyles,
  CircularProgress,
  alpha,
} from "@material-ui/core";
import { amber, green, grey, red } from "@material-ui/core/colors";
import clsx from "clsx";
import CardBase from "../CardBase";
import TimeDurationTableCard from "./TimeDurationTableCard";

const useStyles = makeStyles((theme) => ({
  chargeableMonitorCircle: {
    strokeLinecap: "round",
  },
  chargeableMonitorOk: {
    color: green[500],
  },
  chargeableMonitorWarning: {
    color: amber[500],
  },
  chargeableMonitorDanger: {
    color: red[500],
  },
  chargeableMonitorTop: {
    position: "absolute",
  },
  chargeableMonitorBottom: {
    color: grey[200],
  },
  chargeableMonitorTitle: {
    marginBottom: theme.spacing(4),
  },
  chargeableMonitorDescription: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  iconButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 0,
    color: grey[700],
  },
  headerContainer: {
    display: "flex",
  },
  titleWrapper: {
    flexGrow: 1,
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
  },
  timeItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 4),
  },
  textBold: {
    fontSize: 15,
    fontWeight: 600,
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    color: alpha(theme.palette.primary.main, 0.6),
  },
  divider: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
  budgetMonitorContainer: {
    margin: theme.spacing(2, 0),
    color: alpha(theme.palette.primary.main, 0.6),
    fontWeight: 500,
  },
  monitorContainer: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  monitorPercentageWrapper: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionBtn: {
    margin: theme.spacing(1, 0),
  },
}));

const chargeableMonitorSize = 80;
const chargeableMonitorThickness = 4;
const chargeableMonitorOkThreshold = 65;
const chargeableMonitorWarningThreshold = 85;

const TimeCard = ({
  isLoading,
  isChargeable,
  isUnitised,
  hasBudgetSet,
  totalTime,
  nonChargeableTime,
  chargeableTime,
  budgetHours,
  budgetMinutes,
  budgetTime,
  chargeableTimeUsedPercentage = 0,
}) => {
  const classes = useStyles();

  const chargeableMonitorClass =
    chargeableTimeUsedPercentage < chargeableMonitorOkThreshold
      ? classes.chargeableMonitorOk
      : chargeableTimeUsedPercentage < chargeableMonitorWarningThreshold
      ? classes.chargeableMonitorWarning
      : classes.chargeableMonitorDanger;

  return (
    <CardBase title="Time" isLoading={isLoading}>
      <div className={classes.timeContainer}>
        <TimeDurationTableCard
          isChargeable={isChargeable}
          isUnitised={isUnitised}
          hasBudgetSet={hasBudgetSet}
          totalTime={totalTime}
          chargeableTime={chargeableTime}
          nonChargeableTime={nonChargeableTime}
          budgetTime={budgetTime}
        />
      </div>
      {isChargeable && hasBudgetSet && (
        <div className={classes.budgetMonitorContainer}>
          <div className={classes.monitorContainer}>
            <CircularProgress
              variant="determinate"
              color="inherit"
              thickness={chargeableMonitorThickness}
              size={chargeableMonitorSize}
              className={classes.chargeableMonitorBottom}
              value={100}
            />
            <CircularProgress
              classes={{
                circle: classes.chargeableMonitorCircle,
              }}
              className={clsx(
                classes.chargeableMonitorTop,
                chargeableMonitorClass
              )}
              variant="determinate"
              color="inherit"
              value={
                chargeableTimeUsedPercentage > 100
                  ? 100
                  : chargeableTimeUsedPercentage
              }
              thickness={chargeableMonitorThickness}
              size={chargeableMonitorSize}
            />
            <div className={classes.monitorPercentageWrapper}>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >{`${chargeableTimeUsedPercentage}%`}</Typography>
            </div>
          </div>
          <Typography
            className={classes.chargeableMonitorDescription}
            variant="body2"
          >
            The total chargeable time on this case is{" "}
            {`${chargeableTimeUsedPercentage}%`} of the budgeted {budgetHours}{" "}
            hours
            {budgetMinutes ? ` and ${budgetMinutes} minutes.` : "."}
          </Typography>
        </div>
      )}
    </CardBase>
  );
};

export default TimeCard;

import { useQuery } from "react-query";
import queryKeys from '../../../constants/queryKeys'
import caseService from "../../services/caseService";

const useTimeSummary = ({ fromDate, toDate, displayAll, accountExternalIds }) => useQuery(
    [queryKeys.timeSummary, fromDate, toDate, displayAll, accountExternalIds],
    () => caseService.fetchTimeSummary(fromDate, toDate, displayAll, accountExternalIds),
    {
        enabled:
            !!fromDate ||
            !!toDate ||
            !!displayAll ||
            !!accountExternalIds,
    })

export default useTimeSummary;
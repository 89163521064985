import React, { createContext, useContext, useState } from "react";

const DashboardContext = createContext();

function DashboardProvider({ children }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(new Date());
  const [displayAll, setDisplayAll] = useState(false);

  return (
    <DashboardContext.Provider
      value={{
        fromDate,
        toDate,
        displayAll,
        setFromDate,
        setToDate,
        setDisplayAll,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

const useDashboardFilter = () => useContext(DashboardContext);
export { useDashboardFilter, DashboardProvider };

import { incidentTypes } from "../constants/incidentType";
import CompletedFormBuilder from "./CompletedFormBuilder";
import {isValid} from "date-fns";
import {riddorReportableStatus} from "../contexts/IncidentContext";

class IncidentBuilder extends CompletedFormBuilder {
  constructor(
    uploads,
    responses,
    actions,
    incidentId,
    description,
    incidentDate,
    incidentType,
    siteExternalId,
    people,
    hasInvestigation,
    riddorReportable,
    reportingDate,
    referenceNumber,
    incidentCategoryId,
  ) {
    super(uploads, responses, actions);
    this.incidentId = incidentId;
    this.description = description;
    this.incidentDate = incidentDate;
    this.incidentType = incidentType;
    this.siteExternalId = siteExternalId;
    this.personIdsToDelete = people.ids
      .filter((id) => people.details[id].toBeDeleted)
      .map((id) => people.details[id].id);
    this.people = people;
    this.hasInvestigation = hasInvestigation;
    this.riddorReportable = riddorReportable;
    this.reportingDate = reportingDate;
    this.referenceNumber = referenceNumber;
    this.incidentCategoryId = incidentCategoryId;
  }

  buildIncident() {
    this.result.incidentId = this.incidentId;
    this.result.description = this.description;
    this.result.incidentDate = this.incidentDate;
    this.result.incidentType = this.incidentType;
    this.result.siteExternalId = this.siteExternalId;
    this.result.personIdsToDelete = this.personIdsToDelete;
    this.result.persons = this.buildPersons();
    this.result.hasInvestigation = this.hasInvestigation;
    this.result.riddorReportable = this.riddorReportable;
    this.result.reportingDate = this.reportingDate;
    this.result.referenceNumber = this.referenceNumber?.length > 0 ? this.referenceNumber : null;
    this.result.incidentCategoryId = this.incidentCategoryId;

    return this;
  }

  buildPersons() {
    return this.people.ids
      .filter((id) => !this.people.details[id].toBeDeleted)
      .map((id) => {
        const details = this.people.details[id];

        return {
          name: details.name,
          address: details.address,
          isEmployee: details.isEmployee,
          position: details.role,
          personId: details.id,
          genderType: details.genderType,
          dateOfBirth: details.dateOfBirth,
          incidentAccident:
            this.incidentType === incidentTypes.ACCIDENT
              ? {
                  incidentInjury: details.injuryType,
                  incidentPersonId: details.id,
                  incidentAccidentId: details.incidentAccidentId,
                  hadFirstAid: details.firstAidProvided,
                  incidentBodyParts: details.injuredBodyParts.map(
                    (x) => x.value
                  ),
                }
              : null,
        };
      });
  }
}

export default IncidentBuilder;

const latestBlogPosts = "latest-blog-posts";

const prismicCategories = "prismic-categories";
const prismicCategoriesByParentId = "prismic-categories-parent-id";
const prismicCategory = "prismic-category";

const prismicCategoryArticles = "prismic-category-articles";
const prismicCategoryWebinars = "prismic-category-webinars";
const prismicCategoryPrecedents = "prismic-category-precedents";
const prismicCategoryDownloads = "prismic-category-downloads";

const prismicContentItem = "prismic-content-item";

const accountsTiles = "accounts-tiles";

const userAccounts = "user-accounts";
const consultantAccounts = "consultant-accounts";
const accountSites = "account-sites";
const siteUsers = "site-users";
const currentActions = "account-current-actions";
const currentActionsSummary = "account-current-actions-summary";
const action = "action";
const templateForms = "template-forms";
const accountConsultants = "account-consultants";
const siteAudits = "site-audits";
const auditActionStats = "audit-action-stats";
const auditActionRatingStats = "audit-action-rating-stats";
const accountAdminApps = "account-admin-apps";
const accountLinkableUserSearch = "account-linkable-user-search";
const incidents = "incidents";
const incidentForm = "incident-form";
const daysSinceIncident = "incidents-days";
const incidentBodyParts = "incidents-body-parts";

const salesforceUserDetails = "salesforce-user-details";

const contactCases = "contact-cases";
const contactCasesForHeadlines = "contact-cases-for-headlines";
const caseDetails = "case-details";
const initialData = "initial-data";
const gatherDocumentVersions = "gather-document-versions";
const gatherDocumentPdf = "gather-document-pdf";
const retrieveDocument = "retrieve-document";
const retrieveVideo = "retrieve-document";
const caseActivities = "case-activities";
const salesforceWelcomePackInfo = "salesforce-welcome-pack-info";
const eTCaseDetails = "et-case-details";
const caseActions = "case-actions";

const guidance = "guidance";

const sectors = "sectors";
const subjects = "subjects";
const templates = "templates";
const template = "template";
const templateVersion = "template-version";
const templateVersions = "template-versions";
const templateVersionViews = "template-version-views";
const actionPresets = "action-presets";
const accountDetails = "account-details";
const timeSummary = "time-summary";
const dashboardCases = "dashboard-cases";

const eLTeam = "el-team";

const userProfile = "user-profile";

const documents = "documents";

const templateForm = "template-form";
const incidentSummaryStats = "incidents-summary-stats";
const templateFormHistories = "template-form-histories";
const templateFormHistory = "template-form-history";

const downloadActionAttachment = "download-action-attachment";
const downloadFormAttachment = "download-form-attachment";
const auditPdf = "audit-pdf";
const people = "people";
const hazards = "hazards";
const riskAssessments = "riskAssessments";
const riskAssessment = "riskAssessment";
const controlActions = "controlActions";
const riskAssessmentVersionHistory = "riskAssessmentVersionHistory";
const riskAssessmentHistory = "riskAssessmentHistory";

const userAlertSetting = "user-alert-setting";
const userAccessPermission = "user-access-permission";
const caseNestUserAlertSetting = "casenest-user-alert-setting";

const tutorials = "tutorials";
const tutorialsList = "tutorials-list";
const tutorial = "tutorial";
const tutorialViews = "tutorial-views";

const safetyNestScore = "safetynest-score";
const mostImpactfulActions = "most-impactful-actions";
const safetyNestScoreApplicability = "safetynest-score-applicability";
const siteAuditsLatest = "site-audits-latest";

const appContacts = "app-contacts";
const caseTypes = "case-types";
const caseTime = "case-time";

const downloadIncidentAttachment = "download-incident-attachment";

const siteSearch = "site-search";
const siteHistoricAudits = "site-historic-audits";
const historicAudit = "historic-audit";
const historicAuditActions = "historic-audit-actions";
const historicAction = "historic-action";
const siteMeridianAudits = "site-meridian-audits";

const quickNavigation = "quick-navigation";

const mapData = "map-data";

const precedents = "precedents";

const acknowledgementDetails = "acknowledgement-details";
const verifiedAcknowledgement = "verified-acknowledgement";
const riskAssessmentHistoryAcknowledgementRecipients = "risk-assessment-history-acknowledgement-recipients";

const featureFlag = "feature-flag";

const auditInsights = "audit-insights";
const templateFormInsights = "template-form-insights";
const incidentCategories = "incident-categories";

const queryKeys = {
    safetyNestScore,
    latestBlogPosts,
    prismicCategories,
    prismicCategoriesByParentId,
    prismicCategory,
    prismicCategoryArticles,
    prismicCategoryWebinars,
    prismicCategoryPrecedents,
    prismicCategoryDownloads,
    accountsTiles,
    prismicContentItem,
    userAccounts,
    consultantAccounts,
    accountSites,
    siteUsers,
    currentActions,
    currentActionsSummary,
    accountConsultants,
    siteAudits,
    auditActionStats,
    auditActionRatingStats,
    salesforceUserDetails,
    salesforceWelcomePackInfo,
    contactCases,
    contactCasesForHeadlines,
    caseDetails,
    caseActivities,
    initialData,
    eTCaseDetails,
    caseActions,
    gatherDocumentVersions,
    gatherDocumentPdf,
    retrieveDocument,
    retrieveVideo,
    guidance,
    sectors,
    subjects,
    templates,
    template,
    templateVersion,
    templateVersions,
    templateVersionViews,
    actionPresets,
    templateForms,
    accountDetails,
    timeSummary,
    dashboardCases,
    eLTeam,
    userProfile,
    accountAdminApps,
    accountLinkableUserSearch,
    documents,
    incidents,
    incidentForm,
    templateForm,
    templateFormHistories,
    daysSinceIncident,
    incidentBodyParts,
    action,
    incidentSummaryStats,
    hazards,
    people,
    templateFormHistory,
    userAlertSetting,
    userAccessPermission,
    tutorials,
    tutorialsList,
    tutorial,
    tutorialViews,
    riskAssessments,
    riskAssessment,
    controlActions,
    mostImpactfulActions,
    auditPdf,
    safetyNestScoreApplicability,
    siteAuditsLatest,
    appContacts,
    caseNestUserAlertSetting,
    caseTypes,
    downloadFormAttachment,
    downloadActionAttachment,
    downloadIncidentAttachment,
    siteSearch,
    quickNavigation,
    siteHistoricAudits,
    historicAudit,
    historicAuditActions,
    historicAction,
    caseTime,
    mapData,
    precedents,
    siteMeridianAudits,
    acknowledgementDetails,
    verifiedAcknowledgement,
    riskAssessmentHistoryAcknowledgementRecipients,
    featureFlag,
    auditInsights,
    templateFormInsights,
    riskAssessmentVersionHistory,
    riskAssessmentHistory,
    incidentCategories,
};

export default queryKeys;

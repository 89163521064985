import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import caseService from "../../services/caseService";

const useCaseActivities = ({ caseId, pageSize, pageNum }) =>
  useQuery(
    [queryKeys.caseActivities, caseId, pageSize, pageNum],
    () => caseService.fetchCaseActivities(caseId, pageSize, pageNum),
    {
      enabled: !!caseId,
    }
  );

export default useCaseActivities;

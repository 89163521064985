import axios from "../../utils/axios";
import stringUtils from "../../utils/stringUtils";

const getELTeam = async (accountExternalIds) => {
  const queryString = stringUtils.buildQueryStringList(
    "accountExternalIds",
    accountExternalIds
  );
  return axios.get(
    `${process.env.REACT_APP_LEGAL_URL}/client/getelteam/${queryString}`
  );
};

const teamService = {
  getELTeam,
};

export default teamService;

import axios from "../../utils/axios";
import { buildQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const fetchContactCasesForHeadlines = (
  fromDate,
  toDate,
  displayAll,
  externalIds
) =>
  axios.get(
    apiUrl +
      `/client/getclientcontactcasesforheadlines${buildQueryStringList(
        "externalIds",
        externalIds
      )}`,
    {
      params: {
        fromDate,
        toDate,
        displayAll,
      },
    }
  );

const fetchCasesForContact = (
  caseNumberQuery,
  adviserQuery,
  employeeQuery,
  descriptionQuery,
  pageSize,
  pageNum,
  primaryContactIdQuery,
  displayAll,
  showClosed,
  siteExternalIds,
  caseType
) => {
  let queryString = buildQueryStringList("externalIds", siteExternalIds);
  queryString += queryString ? "&" : "?";
  if (caseNumberQuery) queryString += `&caseNumberQuery=${caseNumberQuery}`;
  if (adviserQuery) queryString += `&adviserQuery=${adviserQuery}`;
  if (employeeQuery) queryString += `&employeeQuery=${employeeQuery}`;
  if (descriptionQuery) queryString += `&descriptionQuery=${descriptionQuery}`;
  if (primaryContactIdQuery)
    queryString += `&primaryContactIdQuery=${primaryContactIdQuery}`;
  if (displayAll) queryString += `&displayAll=${displayAll}`;
  if (showClosed) queryString += `&showClosed=${showClosed}`;
  queryString += `&pageSize=${pageSize}&pageNum=${pageNum}`;

  if (caseType) queryString += `&caseTypeId=${caseType}`;

  return axios.get(`${apiUrl}/client/getclientcontactscases${queryString}`);
};

const fetchCaseDetails = (caseId) =>
  axios.get(apiUrl + "/client/getcasedetails/", {
    params: {
      caseId: caseId,
    },
  });

const fetchCaseActivities = (caseId, pageSize, pageNum) => {
  return axios.get(apiUrl + "/client/getcaseactivities/", {
    params: {
      caseId,
      getCaseActivityIds: true,
      pageSize,
      pageNum,
    },
  });
};

const fetchInitialData = async () => {
  const data = await axios.get(apiUrl + "/client/getinitialdata/");

  const newCaseTypes = data.caseTypes.reduce(
    (prev, curr) => ({ ...prev, [curr.caseTypeId]: { ...curr } }),
    {}
  );
  const newClaimTypes = data.claimTypes.reduce(
    (prev, curr) => ({ ...prev, [curr.claimTypeId]: { ...curr } }),
    {}
  );
  const newPartnerships = data.partnerships.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
    {}
  );
  const newPrecedentFolders = data.precedentFolders.reduce(
    (prev, curr) => ({ ...prev, [curr.precedentFolderId]: { ...curr } }),
    {}
  );
  const newPrecedents = data.precedents.reduce(
    (prev, curr) => ({ ...prev, [curr.precedentId]: { ...curr } }),
    {}
  );
  const newVenues = data.venues.reduce(
    (prev, curr) => ({ ...prev, [curr.venueId]: { ...curr } }),
    {}
  );

  data.caseTypes = newCaseTypes;
  data.claimTypes = newClaimTypes;
  data.partnerships = newPartnerships;
  data.precedentFolders = newPrecedentFolders;
  data.precedents = newPrecedents;
  data.venues = newVenues;

  return data;
};

const fetchDocumentVersions = (documentGuid) =>
  axios.get(apiUrl + "/client/getdocumentversions/" + documentGuid);

const fetchDocument = (fileReference, contentType) =>
  axios.get(apiUrl + "/client/downloaddocument/" + fileReference, {
    responseType: contentType.startsWith("video") ? "stream" : "arraybuffer",
  });

const fetchCaseFiles = async (caseId, downloadOnly) =>
  axios.get(
    apiUrl +
      "/client/getdocumentsforcasedocumentviewer/" +
      caseId +
      "/" +
      downloadOnly
  );

const fetchTimeSummary = (fromDate, toDate, displayAll, accountExternalIds) =>
  axios.get(
    apiUrl +
      `/client/gettimesummary/${buildQueryStringList(
        "externalIds",
        accountExternalIds
      )}`,
    {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        displayAll: displayAll,
      },
    }
  );

const fetchDashboardCases = (
  pageSize,
  pageNum,
  fromDate,
  toDate,
  displayAll,
  accountExternalIds
) =>
  axios.get(
    apiUrl +
      `/getdashboardcases/${buildQueryStringList(
        "externalIds",
        accountExternalIds
      )}`,
    {
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
        fromDate: fromDate,
        toDate: toDate,
        displayAll: displayAll,
      },
    }
  );

const fetchCaseTime = (caseId) =>
  axios.get(apiUrl + `/client/casetime/${caseId}`);

const fetchETCaseDetails = (caseId) =>
  axios.get(apiUrl + `/client/getetcase/${caseId}`);

const fetchOpenAndETCaseActions = (caseId) =>
  axios.get(apiUrl + `/client/getopenandetcaseactions/${caseId}`);

const addCustomerNote = ({ caseId, contractId, subject, message, attachments}) => {
  let formData = new FormData();

  formData.append("caseId", caseId.toString());
  formData.append("contractId", contractId.toString());
  formData.append("subject", subject);
  formData.append("notes", message);

  attachments?.forEach((file) => formData.append("files", file.file));
  
  return axios.post(apiUrl + `/client/addcustomernote`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
})};
  

const updateCustomerNote = ({ caseActivityId, subject, message, newAttachments, filesToDelete }) => {
  let formData = new FormData();

  formData.append("caseActivityId", caseActivityId.toString());
  formData.append("subject", subject);
  formData.append("notes", message);

  newAttachments?.forEach((file) => formData.append("files", file.file));
  filesToDelete?.forEach((file) => formData.append("filesToDelete", file));

  return axios.patch(apiUrl + "/client/updatecustomernote", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
})}  

const caseService = {
  fetchCasesForContact,
  fetchCaseDetails,
  fetchInitialData,
  fetchDocumentVersions,
  fetchDocument,
  fetchCaseActivities,
  fetchCaseFiles,
  fetchContactCasesForHeadlines,
  fetchTimeSummary,
  fetchDashboardCases,
  fetchCaseTime,
  fetchETCaseDetails,
  fetchOpenAndETCaseActions,
  addCustomerNote,
  updateCustomerNote,
};

export default caseService;

import React from "react";
import { useTracking } from "react-tracking";
import IncidentsTableCard from "../components/IncidentsTableCard";
import DaysSinceIncidentCard from "../components/DaysSinceIncidentCard";
import IncidentsBodyPartCard from "../components/IncidentsBodyPartCard";
import { makeStyles, Grid } from "@material-ui/core";
import IncidentsChartCard from "../components/IncidentsChartCard";
import ActionsTableCard from "../components/ActionsTableCard";
import moduleAreas, { moduleIcons } from "../constants/moduleAreaConstants";
import PageBase from "../../components/PageBase";
import PageTitle from "../components/PageTitle";
import SiteSelect from "../../components/SiteSelect";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "3fr 1fr",
    },
    marginTop: theme.spacing(2),
  },
  bodyPartsContainer: {
    gridColumn: "auto",
    [theme.breakpoints.up("md")]: {
      gridColumn: "2/3",
      gridRow: "2 / 3",
    },
  },
  daysSinceContainer: {
    gridColumn: "auto",
    [theme.breakpoints.up("md")]: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
    },
  },
  incidentsTableContainer: {
    gridColumn: "auto",
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / 2",
      gridRow: "1 / 3",
    },
  },
  actionsTableContainer: {
    gridColumn: "auto",
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / 3",
    },
  },
  incidentsChartContainer: {
    gridColumn: "auto",
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / 3",
    },
  },
  titleGrid: {
    marginBottom: theme.spacing(2),
  },
}));

function Incidents() {
  const classes = useStyles();
  const { Track } = useTracking({
    page: `Incidents`,
  });

  return (
    <PageBase>
      <Track>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.titleGrid}
        >
          <Grid item xs={12} md={9}>
            <PageTitle
              icon={moduleIcons.INCIDENTS}
              title="Incidents"
              description="An electronic accident book, giving you visibility of all incidents across your business, allowing you to investigate and then understand trends."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SiteSelect size="small" />
          </Grid>
        </Grid>
        <div className={classes.grid}>
          <div className={classes.daysSinceContainer}>
            <DaysSinceIncidentCard />
          </div>
          <div className={classes.bodyPartsContainer}>
            <IncidentsBodyPartCard />
          </div>
          <div className={classes.incidentsTableContainer}>
            <IncidentsTableCard />
          </div>
          <div className={classes.actionsTableContainer}>
            <ActionsTableCard moduleArea={moduleAreas.INCIDENTS} />
          </div>
          <div className={classes.incidentsChartContainer}>
            <IncidentsChartCard />
          </div>
        </div>
      </Track>
    </PageBase>
  );
}

export default Incidents;

import { Divider, makeStyles } from "@material-ui/core";
import TimeDurationTableCardItem from "./TimeDurationTableCardItem";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  divider: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
}));

const CaseTimeDurationTable = ({
  isChargeable = false,
  isUnitised = false,
  hasBudgetSet = false,
  totalTime,
  chargeableTime,
  nonChargeableTime,
  budgetTime,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TimeDurationTableCardItem
        title="Total"
        time={totalTime}
        isTotal
        isUnitised={isUnitised}
      />
      <Divider className={classes.divider} />
      <TimeDurationTableCardItem
        title="Non-Chargeable"
        time={nonChargeableTime}
        isChargeable={!isChargeable}
        isUnitised={isUnitised}
      />
      <TimeDurationTableCardItem
        title="Chargeable"
        time={chargeableTime}
        isChargeable={isChargeable}
        isUnitised={isUnitised}
      />
      {isChargeable && hasBudgetSet && (
        <TimeDurationTableCardItem
          title="Budget"
          time={budgetTime}
          isUnitised={isUnitised}
        />
      )}
    </div>
  );
};

export default CaseTimeDurationTable;

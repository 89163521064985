import { makeStyles } from "@material-ui/core/styles";

const avatarStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  medium: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
  xlarge: {
    width: theme.spacing(21),
    height: theme.spacing(21),
  },
}));

export default avatarStyles;

import { useQuery } from "react-query";
import queryKeys from '../../../constants/queryKeys'
import caseService from "../../services/caseService";

const useCaseFiles = (caseId, downloadableOnly) => useQuery(
    [queryKeys.caseFiles, caseId, downloadableOnly],
    () => caseService.fetchCaseFiles(caseId, downloadableOnly),
    {
        enabled: !!caseId
    })

export default useCaseFiles;
import React, { Fragment, useEffect, useReducer, useState } from "react";
import CardBase from "./cards/CardBase";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { AddCircleOutline, Delete, Visibility } from "@material-ui/icons";
import ResponsiveSelect from "./ui/ResponsiveSelect";
import {
  tutorialAppearanceModes,
  tutorialAppearanceModesList,
} from "../constants/tutorialAppearanceModes";
import TutorialStep from "./TutorialStep";
import CustomButton from "./CustomButton";
import { nanoid } from "nanoid";
import { grey } from "@material-ui/core/colors";
import { protectedRoutes } from "../routes";
import { routes as safetynestRoutes } from "../safetynest/routes";
import { routes as casenestRoutes } from "../legal/routes";
import TutorialDialog from "./dialogs/TutorialDialog";

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  addStepButton: {
    color: grey[500],
    border: "1px dashed currentColor",
    width: "100%",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
}));

const newId = () => nanoid(5);

const stepActionTypes = {
  ADD_STEP: "add_step",
  INSERT_STEP: "insert_step",
  SET_STEP_CONTENT: "set_step_content",
  DELETE_STEP: "delete_step",
  INIT_STEPS: "init_steps",
};

const initialStepsState = {
  stepIds: [],
  stepContent: {},
};

const stepsReducer = (state, action) => {
  switch (action.type) {
    case stepActionTypes.ADD_STEP: {
      const newStepId = newId();
      return {
        ...state,
        stepIds: [...state.stepIds, newStepId],
        stepContent: {
          ...state.stepContent,
          [newStepId]: "",
        },
      };
    }
    case stepActionTypes.INSERT_STEP: {
      const newStepId = newId();
      const newStepIds = [...state.stepIds];
      newStepIds.splice(action.payload.atIndex, 0, newStepId);

      return {
        ...state,
        stepIds: newStepIds,
        stepContent: {
          ...state.stepContent,
          [newStepId]: "",
        },
      };
    }
    case stepActionTypes.SET_STEP_CONTENT:
      return {
        ...state,
        stepContent: {
          ...state.stepContent,
          [action.payload.stepId]: action.payload.content,
        },
      };
    case stepActionTypes.DELETE_STEP: {
      const newStepContent = { ...state.stepContent };
      delete newStepContent[action.payload.stepId];

      return {
        ...state,
        stepIds: state.stepIds.filter((x) => x !== action.payload.stepId),
        stepContent: newStepContent,
      };
    }
    case stepActionTypes.INIT_STEPS: {
      const stepsWithIds = action.payload.steps.map((x) => ({
        id: newId(),
        content: x,
      }));

      const stepIds = stepsWithIds.map((x) => x.id);
      const stepContent = stepsWithIds.reduce(
        (curr, item) => ({ ...curr, [item.id]: item.content }),
        {}
      );

      return {
        stepIds,
        stepContent,
      };
    }
  }
};

function TutorialBuilder({
  existingTutorial,
  onSubmit,
  isSaving,
  onCancel,
  isLoading = false,
  onDelete = null,
  isDeleting = false,
}) {
  const classes = useStyles();
  const allRoutes = [...protectedRoutes, ...safetynestRoutes, ...casenestRoutes]
    .filter((x) => !x.hideFromTutorials)
    .map((x) => x.path);
  const [title, setTitle] = useState("");
  const [appearanceMode, setAppearanceMode] = useState(
    tutorialAppearanceModes.FIRST_LOGIN
  );
  const [route, setRoute] = useState(allRoutes[0]);
  const [steps, stepsDispatch] = useReducer(stepsReducer, initialStepsState);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewStepIndex, setPreviewStepIndex] = useState(0);
  const [previewTutorial, setPreviewTutorial] = useState(null);

  useEffect(() => {
    if (existingTutorial) {
      setTitle(existingTutorial.title);
      setAppearanceMode(existingTutorial.appearanceMode);
      setRoute(existingTutorial.route);
      if (existingTutorial.steps?.length) {
        stepsDispatch({
          type: stepActionTypes.INIT_STEPS,
          payload: { steps: existingTutorial.steps },
        });
      }
    }
  }, [existingTutorial, stepsDispatch]);

  const addStep = () => {
    stepsDispatch({ type: stepActionTypes.ADD_STEP });
  };

  const insertStep = (atIndex) => {
    stepsDispatch({ type: stepActionTypes.INSERT_STEP, payload: { atIndex } });
  };

  const setStepContent = ({ stepId, content }) => {
    stepsDispatch({
      type: stepActionTypes.SET_STEP_CONTENT,
      payload: { stepId, content },
    });
  };

  const deleteStep = (stepId) => {
    stepsDispatch({
      type: stepActionTypes.DELETE_STEP,
      payload: { stepId },
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      route,
      appearanceMode,
      title,
      steps: steps.stepIds.map((x, index) => ({
        displayOrder: index,
        content: steps.stepContent[x],
      })),
    });
  };

  const handlePreviewClick = () => {
    const previewTutorial = {
      title,
      appearanceMode,
      steps: steps.stepIds.map((x) => steps.stepContent[x]),
    };
    setPreviewTutorial(previewTutorial);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewTutorial(null);
    setPreviewStepIndex(0);
  };

  const handleNextStep = () => {
    setPreviewStepIndex((curr) => curr + 1);
  };

  const handlePreviousStep = () => {
    setPreviewStepIndex((curr) => curr - 1);
  };

  return (
    <>
      <Container>
        <CardBase
          title="Tutorial Builder"
          isLoading={isLoading}
          rightComponent={
            <>
              {onDelete && (
                <CustomButton
                  isLoading={isDeleting}
                  color="error"
                  variant="text"
                  onClick={onDelete}
                  startIcon={<Delete />}
                >
                  Delete
                </CustomButton>
              )}
              <Button
                onClick={handlePreviewClick}
                color="secondary"
                variant="text"
                startIcon={<Visibility />}
              >
                Preview
              </Button>
            </>
          }
        >
          <form onSubmit={onFormSubmit}>
            <Grid container spacing={2} className={classes.fieldsContainer}>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ResponsiveSelect
                  required
                  fullWidth
                  size="small"
                  label="Appearance Mode"
                  options={tutorialAppearanceModesList}
                  optionValueKey="value"
                  optionLabelKey="label"
                  value={appearanceMode}
                  onChange={(e) => setAppearanceMode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ResponsiveSelect
                  required
                  fullWidth
                  size="small"
                  label="Route"
                  options={allRoutes}
                  value={route}
                  onChange={(e) => setRoute(e.target.value)}
                />
              </Grid>
            </Grid>
            <div className={classes.stepsContainer}>
              {steps.stepIds.map((stepId, index) => (
                <Fragment key={stepId}>
                  <TutorialStep
                    stepId={stepId}
                    index={index}
                    isFirst={index === 0}
                    isLast={index + 1 === steps.stepIds.length}
                    isOnly={steps.stepIds.length === 1}
                    content={steps.stepContent[stepId]}
                    onChange={setStepContent}
                    onDelete={deleteStep}
                  />
                  {index + 1 < steps.stepIds.length && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.addStepButton}
                      onClick={() => insertStep(index + 1)}
                      startIcon={<AddCircleOutline />}
                    >
                      Insert Step
                    </Button>
                  )}
                </Fragment>
              ))}
              <Button
                color="secondary"
                variant="outlined"
                className={classes.addStepButton}
                onClick={addStep}
                startIcon={<AddCircleOutline />}
              >
                Add Step
              </Button>
            </div>
            <div className={classes.buttonsContainer}>
              <Button color="primary" variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
              <CustomButton
                type="submit"
                color="primary"
                variant="contained"
                isLoading={isSaving}
              >
                Save
              </CustomButton>
            </div>
          </form>
        </CardBase>
      </Container>
      <TutorialDialog
        activeStepIndex={previewStepIndex}
        tutorial={previewTutorial}
        isOpen={isPreviewOpen}
        onClose={closePreview}
        onDontShowAgain={closePreview}
        onNextStep={handleNextStep}
        onPreviousStep={handlePreviousStep}
      />
    </>
  );
}

export default TutorialBuilder;

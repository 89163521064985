import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useAllModuleTemplates = ({ moduleArea, accountId, siteExternalIds }) =>
  useQuery(
    [queryKeys.templates, moduleArea, accountId, siteExternalIds?.join(",")],
    () =>
      templateService.fetchAllTemplatesForModule({
        moduleArea,
        accountId,
        siteExternalIds,
      })
  );

export default useAllModuleTemplates;

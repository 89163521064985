import PerfectScrollbar from "react-perfect-scrollbar";
import CardBase from "./cards/CardBase";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import CaseHistoryItem from "./CaseHistoryItem";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  perfectScroll: {
    maxHeight: "100vh",
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(1),
  },
  historyItemWrapper: {
    paddingRight: theme.spacing(2),
  },
  pagination: {
    display: "flex",
    justifyContent: "end",
    color: theme.palette.primary.main,
  },
  newNoteBtn: {
    marginRight: theme.spacing(1),
  },
}));

const CaseHistoryComponent = ({
  caseActivities,
  count,
  valuesPerPage,
  numberOfPages,
  pageNumber,
  isLoading,
  isUnitisedTime,
  error,
  onPreviewClick,
  onPageChange,
  onNewNoteClick,
  onSideMenuClick,
}) => {
  const classes = useStyles();
  return (
    <CardBase
      title={`Case History (${count} activit${count === 1 ? "y" : "ies"})`}
      isLoading={isLoading}
          error={error}
      rightComponent={
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onNewNoteClick(true)}
          className={classes.newNoteBtn}
        >
          New Note
        </Button>
      }
    >
      <PerfectScrollbar className={classes.perfectScroll}>
        <div className={classes.historyItemWrapper}>
          {caseActivities &&
            caseActivities?.length > 0 &&
            caseActivities.map((activity) => (
              <CaseHistoryItem
                caseEvent={activity}
                onPreviewClick={onPreviewClick}
                onSideMenuClick={onSideMenuClick}
                isUnitisedTime={isUnitisedTime}
              />
            ))}
        </div>
        {count > valuesPerPage && (
          <Pagination
            count={numberOfPages}
            page={pageNumber}
            onChange={onPageChange}
            className={classes.pagination}
            color="secondary"
          />
        )}
      </PerfectScrollbar>
    </CardBase>
  );
};

export default CaseHistoryComponent;

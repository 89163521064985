import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from "@material-ui/core";
import useRetrieveDocument from "../hooks/queries/useRetrieveDocument";
import CardBase from "../../components/cards/CardBase";
import { Image, Videocam, InsertDriveFile } from "@material-ui/icons";

const fileIcons = {
  image: <Image />,
  video: <Videocam />,
  other: <InsertDriveFile />,
};

const EvidenceCard = ({ files, openFileViewer }) => {
  const [file, setFile] = useState([]);

  const { data, isLoading, error, isFetching } = useRetrieveDocument({
    fileReference: file.reference,
    contentType: file.contentType,
  });

  const openDocument = async (file) => {
    setFile(file);
  };

  useEffect(() => {
    if (!data || !file) return;

    const splitFilename = file.filename.split(".");
    const extension = splitFilename[splitFilename.length - 1];
    const filename = file.filename;

    if (file.contentType.startsWith("video")) {
      openFileViewer({
        type: extension,
        path: data,
        name: filename,
      });
    } else {
      const blob = new Blob([data], { type: file.contentType });
      const blobPath = window.URL.createObjectURL(blob);
      openFileViewer({
        type: extension,
        path: blobPath,
        name: filename,
      });
    }
  }, [file, data]);

  return (
    <CardBase
      title="Evidence"
      isLoading={isLoading}
      error={error}
      isFetching={isFetching}
    >
      {files?.length === 0 ? (
        <Typography>No evidence files.</Typography>
      ) : (
        <List>
          {files.map((f) => (
            <ListItem key={f.reference}>
              <ListItemAvatar>
                <Avatar>
                  {f.contentType.startsWith("image")
                    ? fileIcons.image
                    : f.contentType.startsWith("video")
                    ? fileIcons.video
                    : fileIcons.other}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Button onClick={() => openDocument(f)} color="primary">
                    {f.filename}
                  </Button>
                }
                secondary={
                  f.uploadedByName ? (
                    <span>
                      Uploaded by {f.uploadedByName} on{" "}
                      {format(new Date(f.uploadedOn), "dd/MM/yyyy HH:mm")}
                    </span>
                  ) : (
                    <span>
                      {format(new Date(f.uploadedOn), "dd/MM/yyyy HH:mm")}
                    </span>
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </CardBase>
  );
};

export default EvidenceCard;

import { buildQueryStringList, addQueryStringList } from "../../utils/stringUtils";
import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchIncidentsForSites = ({ externalIds, pageNum, numPerPage, sortOrder, orderByColumn, fromDate, toDate, assignees, filteredText, filteredPersonText, riddorReportable, incidentCategoryId, }) => {
    let queryString = buildQueryStringList("externalIds", externalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);

    if (queryStringAssignees !== null)
        queryString += queryStringAssignees;

    if (fromDate !== null)
        queryString += `&fromDate=${fromDate}`;

    if (toDate !== null)
        queryString += `&toDate=${toDate}`;

    if (filteredText)
        queryString += `&filteredText=${filteredText}`;

    if (filteredPersonText)
        queryString += `&filteredPersonText=${filteredPersonText}`;
    
    if (riddorReportable !== undefined && riddorReportable !== null)
        queryString += `&riddorReportable=${riddorReportable}`;
    
    if (incidentCategoryId !== undefined && incidentCategoryId !== null)
        queryString += `&incidentCategoryId=${incidentCategoryId}`;

    queryString += `&page=${pageNum}&rows=${numPerPage}&sortOrder=${sortOrder}&orderByColumn=${orderByColumn}`;

    return axios.get(`${apiUrl}/Incident/Sites${queryString}`);
};

const fetchDaysSinceIncidentForSites = ({ externalIds }) => {
    const queryString = buildQueryStringList("externalIds", externalIds);
    return axios.get(`${apiUrl}/Incident/Sites/Latest${queryString}`);
};

const addHistoricIncident = ({ siteExternalId, incidentDate }) =>
    axios.post(`${apiUrl}/Incident/Historic`, { siteExternalId, incidentDate });

const fetchIncidentSummaryStats = (externalIds, fromDate, toDate, weekCount) =>
    axios.get(`${apiUrl}/Incident/Sites/Summary${buildQueryStringList("externalIds", externalIds)}`,
        {
            params: {
                fromDate,
                toDate,
                weekCount,
            },
        }
    );

const fetchBodyPartStatsForSites = ({ externalIds, fromDate }) =>
    axios.get(`${apiUrl}/Incident/Sites/Stats${buildQueryStringList("externalIds", externalIds)}`,
        {
            params: {
                fromDate,
            },
        }
    );

const fetchIncidentResponsesById = (id) => {
    return axios.get(`${apiUrl}/Incident/Form/Responses/${id}`);
};

const addIncident = (incident) => axios.post(`${apiUrl}/Incident`, incident);

const updateIncident = (incident) =>
    axios.patch(`${apiUrl}/Incident/Update`, incident);

const uploadAttachments = ({ files, onUploadProgress }) => {
    const formData = new FormData();
    for (const [index, dto] of files.entries()) {
        formData.append(`[${index}].syncId`, dto.syncId);
        formData.append(`[${index}].file`, dto.file);
    }
    return axios.post(`${apiUrl}/Incident/Attachments`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};

const downloadAttachment = (reference) =>
    axios.get(`${apiUrl}/Incident/Attachment/${reference}`, {
        responseType: "arraybuffer",
    });

const downloadIncidentsForSite = ({ siteExternalIds, fromDate, toDate, assignees, filteredText, filteredPersonText, riddorReportable, incidentCategoryId, }) => {
    let queryString = buildQueryStringList("externalIds", siteExternalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);

    if (queryStringAssignees !== null) queryString += queryStringAssignees;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    if (filteredText) queryString += `&filteredText=${filteredText}`;
    if (filteredPersonText) queryString += `&filteredPersonText=${filteredPersonText}`;
    if (riddorReportable !== undefined && riddorReportable !== null) queryString += `&riddorReportable=${riddorReportable}`;
    if (incidentCategoryId !== undefined && incidentCategoryId !== null) queryString += `&incidentCategoryId=${incidentCategoryId}`;

    return axios.get(`${apiUrl}/Incident/Sites/download${queryString}`);
};

const downloadIncidentPdf = (incidentId, isDriIncident = false) =>
    axios.get(`${apiUrl}/incident/downloadpdf/${incidentId}/${isDriIncident}`, {
        responseType: "blob",
    });

const deleteIncident = (incidentUpdate) =>
    axios.post(`${apiUrl}/incident/archive/${incidentUpdate.id}/${incidentUpdate.isDri}`);

const fetchIncidentCategories = () =>
    axios.get(`${apiUrl}/incidentCategories`);

const incidentService = {
    addIncident,
    fetchIncidentsForSites,
    fetchDaysSinceIncidentForSites,
    addHistoricIncident,
    fetchIncidentSummaryStats,
    fetchBodyPartStatsForSites,
    fetchIncidentResponsesById,
    updateIncident,
    downloadIncidentsForSite,
    uploadAttachments,
    downloadAttachment,
    downloadIncidentPdf,
    deleteIncident,
    fetchIncidentCategories,
};

export default incidentService;

import { makeStyles, Paper, Typography } from "@material-ui/core";
import layoutConstants from "../../constants/layoutConstants";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    height: `${layoutConstants.headlineTileHeight}px`,
    width: `${layoutConstants.headlineTileWidth}px`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    textTransform: "none",
    fontSize: "12px",
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  figure: {
    textAlign: "center",
    fontWeight: 500,
    textTransform: "none",
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const HeadlineTile = ({ title, data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <div className={classes.cardTextContainer}>
        <Typography variant="h4" className={classes.figure}>
          {data}
        </Typography>
        <Typography className={classes.title}>{title}</Typography>
      </div>
    </Paper>
  );
};

import React, { useMemo, useCallback, useState } from "react";
import {
    makeStyles,
    Box,
    Container,
    Grid,
    Typography,
    TextField,
    InputAdornment,
    useMediaQuery,
    Divider,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import ResourceNestLogo from "../images/ResourceNest_Purple_Positive_RGB.svg";
import usePrecedents from "../hooks/queries/usePrecedents";
import DocumentGrid from "./DocumentGrid";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '100%',
        maxWidth: '120px',
        marginBottom: '10px'
    },
    caseTypeTile: {
        padding: '10px',
        paddingTop: 0,
        height: '70px',
        color: '#0e014a',
        cursor: 'pointer',
        borderBottom: '1px solid #dfdee9',
    },
    count: {
        color: '#593bcc',
        fontSize: '0.9em'
    }
}));

const ResourceNest = () => {
    const classes = useStyles();
    const { Track } = useTracking({
        page: 'ResourceNest'
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    const { data, isLoading } = usePrecedents();

    const [filter, setFilter] = useState("");    

    const allPrecedents = useMemo(() => data?.precedents || [], [data]);

    const filteredPrecedents = useMemo(() => {
        if (!filter)
            return allPrecedents;

        return allPrecedents.filter(p => p.name.toLowerCase().includes(filter.toLowerCase()));
    }, [filter, allPrecedents]);    

    const precedentCount = useCallback((caseTypeId, folderId) => {
        
        let list = filteredPrecedents.filter(p => p.caseTypeId === caseTypeId);

        if (folderId !== undefined)
            list = list.filter(p => (p.precedentFolderId || null) === folderId);
        
        return list.length;
    }, [filteredPrecedents]);
    
    const caseTypes = useMemo(() => {
        let val = data?.caseTypes || [];
        return val.filter(ct => precedentCount(ct.caseTypeId) > 0);
    }, [data, precedentCount]); 

    const [selectedCaseType, setSelectedCaseType] = useState();

    const filteredFolders = useMemo(() => {
        if (!selectedCaseType)
            return [];

        let val = data?.folders || [];        

        val = val.filter(f => f.caseTypeId === selectedCaseType.caseTypeId);

        val.push({
            name: 'Miscellaneous',
            precedentFolderId: null
        });

        val = val.filter(f => precedentCount(selectedCaseType.caseTypeId, f.precedentFolderId) > 0);

        return val;
    }, [data, selectedCaseType, precedentCount]);    

    const CaseTypeGrid = () => {
        return (
            <Grid container spacing={2}>
                {
                    caseTypes.map(ct => (
                        <Grid xs={12} sm={6} md={4} lg={3} item key={ct.caseTypeId} onClick={() => setSelectedCaseType(ct)}>
                            <Box className={classes.caseTypeTile} display="flex" gridGap="5px">
                                <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
                                    <Box><Typography>{ct.name}</Typography></Box>
                                    <Box><Typography className={classes.count}>{precedentCount(ct.caseTypeId)} document{precedentCount(ct.caseTypeId) > 1 && "s"}</Typography></Box>       
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="center">
                                    <ArrowForwardIcon />
                                </Box>
                                
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }    

    return (
        <PageBase>
            <Track>
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <img src={ResourceNestLogo} alt="logo" className={classes.logo} />
                    <h1 variant="h3">Explore Documents</h1>
                    <Typography>Find curated documents on specific Employee Relations topics.  Get started now.</Typography>
                    <TextField
                        style={{margin: '20px'}}
                        placeholder="Search Documents"
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        size="small"
                        fullWidth={isMobile}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Divider style={{ margin: '10px' }} />
                {
                    !isLoading && filteredPrecedents.length === 0 &&
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                        <h1>No documents available with selected search term.</h1>
                    </Box>
                }
                <Container>
                    {
                        selectedCaseType
                            ? <DocumentGrid
                                filteredPrecedents={filteredPrecedents}
                                filteredFolders={filteredFolders}
                                selectedCaseType={selectedCaseType}
                                setSelectedCaseType={setSelectedCaseType}
                                filter={filter}
                                setFilter={setFilter}
                                precedentCount={precedentCount}
                            />
                            : <CaseTypeGrid />
                    }
                </Container>                
            </Track>
        </PageBase>
    );
}

export default ResourceNest;

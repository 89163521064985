import React from "react";
import { useDashboardFilter } from "../contexts/dashboardContext";
import CardBase from "../../components/cards/CardBase";
import useTimeSummary from "../hooks/queries/useTimeSummary";
import { formatShortTime } from "../../utils/dateTimeUtils";
import { useSelector } from "react-redux";
import { ResponsivePie } from "@nivo/pie";

import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    pieSettings: {
        textAlign: "center",
        position: "relative",
        height: 280
    },
    tooltip: {
        padding: 12,
        color: "white",
        background: '#222222',
    },
    overlay: {
        position: "absolute",
        top: 10,
        right: 50,
        bottom: 0,
        left: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 28,
        color: "#000",
        textAlign: "center",
        pointerEvents: "none"
    },
}));



function TimeSummaryChartCard() {
    const classes = useStyles();
    const { fromDate, toDate, displayAll } = useDashboardFilter();
    const { selectedSite, activeAppId, appSiteIds } = useSelector(
        (state) => state.account
    );

    const { data, isLoading, error, isFetching } = useTimeSummary({
        fromDate,
        toDate,
        displayAll,
        accountExternalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
    });

    const chartData =
        data?.details?.length &&
        data.details.map((s) => ({
            id: s.caseType,
            label: s.caseType,
            value: s.totalTimeSeconds,
            percentage: Math.round((s.totalTimeSeconds / data.totalCaseTime) * 100)
        }));

    const totalTime = data?.details?.length && formatShortTime(data.totalCaseTime);

    const ResponsivePieChart = ({ data }) => (
        <div className={classes.pieSettings}>
            <ResponsivePie
                data={data}
                margin={{ top: 40, right: 10, bottom: 30, left: 10 }}
                innerRadius={0.8}
                padAngle={0.7}
                cornerRadius={5}
                activeOuterRadiusOffset={8}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                animate={true}
                enableArcLabels={false}
                sliceLabel={(item) => `${item.value}%`}
                tooltip={(input) => {
                    return (
                        <Box className={classes.tooltip}>
                            <Box display="flex" justifyContent="center">
                                <Typography variant="h6">{input.datum.data.label}: {input.datum.data.percentage}%</Typography>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Typography variant="subtitle2"> Time: {formatShortTime(input.datum.data.value)}</Typography>
                            </Box>
                        </Box>
                    )
                }}
            />
            <Typography className={classes.overlay}>{totalTime}</Typography>
        </div>
    );

    const cardContent = () => {
        if (chartData)
            return (
                <ResponsivePieChart data={chartData} />
            );
        return <Typography>No Time Data</Typography>;
    };

    return (
        <CardBase
            title={displayAll ? "Case Time breakdown - All Cases" : "Case Time breakdown - My Cases" }
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            fullHeight
        >
            {cardContent()}
        </CardBase>
    );
}

export default TimeSummaryChartCard;

import { alpha, Avatar, IconButton, makeStyles, Typography } from "@material-ui/core";
import { red, teal } from "@material-ui/core/colors";
import { ArrowDownward, ArrowUpward, Attachment, MoreVert } from "@material-ui/icons";
import { useMemo } from "react";
import { CALL, OUTCOME, MISC, EMAIL } from "../constants/caseActivityTypes";
import {
  formatDigitalTime,
  formatTime,
  formatShortMonthDateAndTime,
} from "../../utils/dateTimeUtils";
import { getInitials } from "../../utils/nameUtils";
import { getUnitEnding } from "../../utils/unitTimeUtils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.common.white,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    margin: theme.spacing(3, 0),
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  user: {
    display: "flex",
  },
  userImg: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
  },

  activityDate: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  activityDescription: {
    fontSize: 13,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  activityDescriptionButton: {
    marginTop: theme.spacing(1.5),
  },
  middleSection: {
    display: "flex",
    flexDirection: "column",
  },
  audio: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 40,
  },
  bottomSection: {
    display: "flex",
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  direction: {
    padding: theme.spacing(0.25, 0.5),
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    background: alpha(theme.palette.primary.main, 0.04),
    marginRight: theme.spacing(2),
  },
  backgroundOutbound: {
    background: alpha(theme.palette.secondary.light, 0.225),
  },
  time: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    borderRadius: 4,
    background: alpha(theme.palette.primary.main, 0.04),
    marginRight: theme.spacing(2),
  },
  attachments: {
    padding: theme.spacing(1),
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    background: alpha(theme.palette.primary.main, 0.04),
    marginRight: theme.spacing(2),
  },
  chargable: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    background: teal[500],
    color: theme.palette.common.white,
  },
  downloadCheckbox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  arrowIcon: {
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  precedentIcon: {
    fontSize: 14,
    color: red[800],
    transform: "rotate(270deg)",
  },
  attachmentIcon: {
    fontSize: 14,
    transform: "rotate(270deg)",
  },
  iconButton: {
    padding: 0,
  },
  textBold: {
    fontSize: 13,
    fontWeight: 600,
    wordBreak: "break-all",
  },
  chargableText: {
    fontSize: 15,
  },
  pointer: {
    cursor: "pointer",
  },
}));

const CaseHistoryItem = ({
  caseEvent,
  onPreviewClick,
  onSideMenuClick,
  isUnitisedTime = false,
}) => {
  const cardIsClickable = useMemo(
    () => !(caseEvent.itemType === CALL || caseEvent.itemType === OUTCOME),
    [caseEvent]
  );

  const classes = useStyles({
    direction: caseEvent?.direction,
    cardIsClickable,
  });

  const displayTitle = useMemo(() => {
    if (caseEvent.itemType === MISC) {
      if (caseEvent.miscActivityIsCustomerNote)
        return caseEvent.miscActivityTypeDescription;

      return `Misc Activity - ${caseEvent.miscActivityTypeDescription}`;
    }

    return caseEvent.itemType;
  }, [caseEvent]);

  const hasSideMenuAccess = useMemo(
    () => caseEvent.miscActivityIsCustomerNote,
    [caseEvent]
  );

  const displayDuration = useMemo(() => {
    const duration = caseEvent?.duration ?? 0;
    if (!isUnitisedTime) return formatDigitalTime(duration);

    let units = caseEvent?.units ?? 0;

    const formatTime = formatDigitalTime(caseEvent?.unitisedSeconds ?? 0);
    const formatUnits = `(${units} ${getUnitEnding(units)})`;
    return `${formatTime} ${formatUnits}`;
  }, [caseEvent, isUnitisedTime]);

  const handleSideMenuClick = (e) => {
    e.stopPropagation();
    return onSideMenuClick(caseEvent, e);
  };

  const handleCardClick = () => {
    if (!cardIsClickable) return;
    onPreviewClick(caseEvent);
  };

  return (
    <div
      className={clsx(
        classes.container,
        cardIsClickable ? classes.pointer : null
      )}
      onClick={handleCardClick}
      data-testid="case-history-item"
    >
      <div className={classes.topSection} data-id={caseEvent.caseActivityId}>
        <div className={classes.user}>
          <div className={classes.userImg}>
            <Avatar
              alt={caseEvent.person}
              src={caseEvent?.adviserPhoto}
              className={classes.userImg}
            >
               {!!caseEvent.direction || caseEvent.miscActivityIsCustomerNote
                ? getInitials(caseEvent.adviserName ?? caseEvent.person)
                : null}
            </Avatar>
          </div>
          <div className={classes.userInfo}>
            <Typography
              color="primary"
              variant="body2"
              className={classes.textBold}
            >
              {caseEvent.itemType === CALL
                ? caseEvent.caller
                : caseEvent.person}
            </Typography>
            <Typography variant="body2" className={classes.activityDate}>
              {formatShortMonthDateAndTime(caseEvent.eventTime)}
            </Typography>
          </div>
        </div>
        <div>
          {hasSideMenuAccess && (
            <IconButton
              onClick={handleSideMenuClick}
            >
              <MoreVert />
            </IconButton>
          )}
        </div>
      </div>
      <div className={classes.middleSection}>
        <Typography
          color="primary"
          variant="body2"
          className={classes.textBold}
        >
          {displayTitle}
        </Typography>
        {!caseEvent.hideCallRecording && caseEvent.recordingUrl && (
          <div>
            <audio controls className={classes.audio}>
              <source src={caseEvent.recordingUrl} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        {caseEvent?.itemType === EMAIL && caseEvent?.direction === 0 ? (
          <>
            <Typography variant="body2" className={classes.activityDescription}>
              {caseEvent?.activityDescription ||
                caseEvent?.text ||
                "No description added"}
            </Typography>
          </>
        ) : (
          <Typography variant="body2" className={classes.activityDescription}>
            {caseEvent?.activityDescription || caseEvent?.text}
          </Typography>
        )}
      </div>
      {caseEvent.hideCallRecording && caseEvent.callDuration && (
        <Typography variant="body2" className={classes.activityDescription}>
          Call Duration: {formatTime(caseEvent.callDuration)}
        </Typography>
      )}
      {!!caseEvent.caseActivityId && (
        <>
          <div className={classes.bottomSection}>
            {caseEvent?.direction === 0 && (
              <div className={classes.direction}>
                <ArrowDownward className={classes.arrowIcon} />
                <Typography
                  color="primary"
                  variant="body2"
                  className={classes.textBold}
                >
                  Inbound
                </Typography>
              </div>
            )}
            {caseEvent?.direction === 1 && (
              <div
                className={clsx(classes.direction, classes.backgroundOutbound)}
              >
                <ArrowUpward className={classes.arrowIcon} />
                <Typography
                  color="primary"
                  variant="body2"
                  className={classes.textBold}
                >
                  Outbound
                </Typography>
              </div>
            )}
            {!!caseEvent?.duration && (
              <div className={classes.time}>
                <Typography
                  color="primary"
                  variant="body2"
                  className={classes.textBold}
                >
                  {displayDuration}
                </Typography>
              </div>
            )}
            {caseEvent?.hasPrecedents && (
              <div className={classes.attachments}>
                <Attachment className={classes.precedentIcon} />
              </div>
            )}
            {caseEvent?.attachments && caseEvent?.attachments.length > 0 && (
              <div className={classes.attachments}>
                <Attachment className={classes.attachmentIcon} />
              </div>
            )}
            {caseEvent?.isChargeable && (
              <div className={classes.chargable}>
                <Typography variant="body2" className={classes.chargableText}>
                  £
                </Typography>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CaseHistoryItem;

import { useQuery } from "react-query";
import queryKeys from '../../../constants/queryKeys'
import accountService from "../../services/accountService";

const useAccountDetails = ({ accountExternalId }) => useQuery(
    [queryKeys.accountDetails, accountExternalId],
    () => accountService.fetchAccountDetails(accountExternalId),
    {
        enabled:
            !!accountExternalId
    }

)

export default useAccountDetails;
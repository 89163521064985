import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const getPrecedents = () => {

    return axios.get(`${apiUrl}/client/getprecedents`);
};

const resourceNestService = {
    getPrecedents
};

export default resourceNestService;

import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { formatPriceGBP } from "../../../../utils/priceUtils";

const useStyles = makeStyles((theme) => ({
  boxSpacing: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing(3),
  },
  rightHalf: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
  },
  fullGrid: {
    gridColumn: "1 / span 3",
    display: "flex",
    alignItems: "center",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  leftHalf: {
    minWidth: "max-content",
  },
  loadingErrorContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  subtitle: {
    fontWeight: 600,
  },
}));

export const ETAwardsSettlementsCard = ({
  etClaim,
  isLoading,
  isError,
  error,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className={classes.boxSpacing}>
          <div className={classes.fullGrid}>
            <Typography className={classes.leftHalf} variant="h6">
              Awards & Settlements
            </Typography>
          </div>

          {isLoading && (
            <>
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
            </>
          )}

          {isError && (
            <div className={classes.loadingErrorContainer}>
              <Alert variant="outlined" severity="error">
                {error?.message ||
                  "Unable to load Awards and Settlements details"}
              </Alert>
            </div>
          )}

          {!isLoading && !isError && (
            <>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Estimated Worst Case Award
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(etClaim?.estimatedWorstCaseAward)}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Estimated Likely Award
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(etClaim?.estimatedLikelyAward)}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Actual Award
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(etClaim?.actualAward)}
                </Typography>
              </div>

              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Recommended Settlement range from
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(etClaim?.recommendedSettlementRangeFrom)}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Recommended Settlement range to
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(etClaim?.recommendedSettlementRangeTo)}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Actual Settlement
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(etClaim?.actualSettlement)}
                </Typography>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ETAwardsSettlementsCard;

import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import caseService from "../../services/caseService";

const useCaseDetails = ({ caseId }) =>
  useQuery(
    [queryKeys.caseDetails, caseId],

    () => caseService.fetchCaseDetails(caseId),
    {
      enabled: !!caseId,
    }
  );

export default useCaseDetails;

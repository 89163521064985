import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";
import { useDispatch } from "react-redux";
import { setActiveAppId } from "../redux/actions/accountActions";
import appIds from "../constants/appIdConstants";
import RouteMapper from "../components/RouteMapper";
import { routes } from "./routes";

function LegalIndex() {
  const dispatch = useDispatch();
  const { Track } = useTracking({
    app: trackingConstants.legalAppName,
  });

  useEffect(() => {
    dispatch(setActiveAppId(appIds.casenest));
  }, [dispatch]);

  return (
    <Track>
      <Switch>
        <RouteMapper routes={routes} />
      </Switch>
    </Track>
  );
}

export default LegalIndex;

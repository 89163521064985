import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import resourceNestService from "../../services/resourceNestService";

const usePrecedents = () =>
    useQuery(
        [queryKeys.precedents],
        resourceNestService.getPrecedents,
        {
            staleTime: Infinity
        }
    )

export default usePrecedents;
import React from "react";
import {
    MenuItem,
    TextField,
} from "@material-ui/core";
import useIsMobile from "../../hooks/useIsMobile";

function ResponsiveSelect({
    options = [],
    optionValueKey = null,
    optionLabelKey = null,
    optionLabelFunction = null,
    ...rest
}) {
    const isMobile = useIsMobile();

    const displayOption = (selectOption, index) => {
        const value = optionValueKey ? selectOption[optionValueKey] : selectOption;
        const label = optionLabelKey
            ? selectOption[optionLabelKey]
            : optionLabelFunction
                ? optionLabelFunction(selectOption)
                : selectOption;

        return isMobile ? (
            <option key={index} value={value}>
                {label}
            </option>
        ) : (
            <MenuItem key={index} value={value}>
                {label}
            </MenuItem>
        );
    };

    return (
        <TextField
            variant="outlined"
            select
            {...rest}
            native={isMobile.toString()}
            SelectProps={{ native: isMobile }}
        >
            {options.map(displayOption)}
        </TextField>
    );
}

export default ResponsiveSelect;

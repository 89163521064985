import React from "react";
import CardBase from "../../components/cards/CardBase";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

function CasePermissionsCard({ isVisibleToContactsOnly }) {
  const classes = useStyles();

  return (
    <CardBase title="Permissions">
      <div className={classes.container}>
        {isVisibleToContactsOnly ? (
          <Alert icon={false} severity="error">
            Only visible to contacts on this case.
          </Alert>
        ) : (
          <Alert icon={false} severity="success">
            Visible to all contacts on this account.
          </Alert>
        )}
      </div>
    </CardBase>
  );
}

export default CasePermissionsCard;

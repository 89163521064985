import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const fetchAccountDetails = (accountExternalId) =>
  axios.get(`${apiUrl}/client/getaccountsummary`, {
    params: {
      externalId: accountExternalId,
    },
  });

const accountService = {
  fetchAccountDetails,
};

export default accountService;

import React from "react";
import { Chip, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";
import chipStyles from "../../theme/chips";
import CardBase from "../../components/cards/CardBase";

const useStyles = makeStyles(() => ({
  mainGrid: {
    marginBottom: "16px",
    marginTop: "16px",
  },
  gridItem: {
    paddingRight: "16px",
  },
}));

const CaseSummaryCard = ({ caseDetails }) => {
  const classes = useStyles();
  const chipClasses = chipStyles();

  const tableData = {
    "Case Type": caseDetails.caseType,
    "Current Stage": caseDetails.caseStage,
    Created: format(new Date(caseDetails.dateCreated), "dd/MM/yyyy"),
    "Created By": caseDetails.createdBy,
    Contract: caseDetails.contract ?? "N/A",
  };

  const closedCaseTableData = {
    Closed: caseDetails.dateClosed
      ? format(new Date(caseDetails.dateClosed), "dd/MM/yyyy")
      : "N/A",
    Outcome: caseDetails.caseOutcome || "N/A",
  };

  const table = (data) =>
    Object.keys(data).map((key, index) => (
      <Grid key={index} container className={classes.mainGrid}>
        <Grid item className={classes.gridItem} xs={12} sm={6}>
          <Typography variant="body2">{key}</Typography>
        </Grid>
        <Grid item className={classes.gridItem} xs={12} sm={6}>
          <Typography variant="subtitle2">{data[key]}</Typography>
        </Grid>
      </Grid>
    ));

  return (
    <CardBase
      title={`Case #${caseDetails.id}`}
      rightComponent={
        <Chip
          size="small"
          className={
            caseDetails.dateClosed ? chipClasses.red : chipClasses.green
          }
          label={caseDetails.dateClosed ? "Closed" : "Open"}
        />
      }
    >
      {table(tableData)}
      {!!caseDetails.dateClosed && (
        <>
          <Divider />
          {table(closedCaseTableData)}
        </>
      )}
    </CardBase>
  );
};

export default CaseSummaryCard;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDashboardFilter } from "../contexts/dashboardContext";
import CardBase from "../../components/cards/CardBase";
import {
  Avatar,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import useContactCases from "../hooks/queries/useContactCases";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { green, orange, purple } from "@material-ui/core/colors";
import { setContactCasesTablePage } from "../../redux/actions/tablePageActions";
import { tablePage } from "../../safetynest/constants/tablePageConstants";

const useStyles = makeStyles(() => ({
  moduleChip: {
    color: "white",
    borderRadius: "4px",
    "&.module-0": {
      backgroundColor: green[400],
    },
    "&.module-1": {
      backgroundColor: purple[400],
    },
    "&.module-2": {
      backgroundColor: orange[400],
    },
  },
  userPhoto: {
    marginRight: "8px",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  employeeBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "-8px 0",
  },
  searchControl: {
    minWidth: 120,
    paddingBottom: "8px",
    marginRight: "10px",
  },
  tableRow: {
    "&:hover": {
      background: "#e7def6",
      cursor: "pointer",
    },
  },
}));

const tableKey = tablePage.CONTACT_CASES_TABLE;

function ContactCasesTableCard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );
  const { fromDate, toDate, displayAll } = useDashboardFilter();

  const { contactCasesTable } = useSelector((state) => state.tablePage);

  const history = useHistory();

  const { data, isLoading, error, isFetching } = useContactCases({
    pageSize: contactCasesTable.rowsPerPage,
    pageNum: contactCasesTable.page + 1,
    fromDate,
    toDate,
    displayAll,
    showClosed: false,
    siteExternalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });

  const handleChangePage = (event, newPage) => {
    dispatch(
      setContactCasesTablePage({
        ...contactCasesTable,
        key: tableKey,
        page: newPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setContactCasesTablePage({
        ...contactCasesTable,
        key: tableKey,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      })
    );
  };

  const handleClick = async (caseId) => {
    if (caseId === "") return;
    history.push(`/legal/case/${caseId}`);
  };

  const tableContent = () => {
    if (data && data.count > 0)
      return data.cases.map((row) => {
        return (
          <TableRow
            key={row.caseId}
            className={classes.tableRow}
            onClick={() => handleClick(row.caseId)}
          >
            <TableCell component="th" scope="row">
              {row.caseId}
            </TableCell>
            <TableCell>{row.description}</TableCell>
            {!selectedSite && <TableCell>{row.account}</TableCell>}
            <TableCell>
              {format(new Date(row.dateCreated), "dd/MM/yyyy")}
            </TableCell>
            <TableCell>
              {format(new Date(row.lastModifiedDate), "dd/MM/yyyy")}
            </TableCell>
            <TableCell>
              <Box className={classes.userBox}>
                <Avatar className={classes.userPhoto} alt={row.adviser} />
                {row.adviser}
              </Box>
            </TableCell>
            <TableCell>
              <Box className={classes.userBox}>
                <Avatar
                  className={classes.userPhoto}
                  alt={row.primaryContactName}
                />
                {row.primaryContactName}
              </Box>
            </TableCell>
            <TableCell>{row.caseType}</TableCell>
            <TableCell>
              <Box className={classes.employeeBox}>
                {row.employees.map((e) => (
                  <React.Fragment key={e.employeeId}>
                    <Box className={classes.userBox}>
                      <Avatar
                        className={classes.userPhoto}
                        alt={e.name}
                        src={e.photo}
                      />
                      {e.name}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </TableCell>
          </TableRow>
        );
      });

    return (
      <TableRow>
        <TableCell colSpan={4}>No current tasks.</TableCell>
      </TableRow>
    );
  };

  return (
    <CardBase
      title="Recent Cases"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
    >
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Description</TableCell>
              {!selectedSite && <TableCell>Account</TableCell>}
              <TableCell>Date Created</TableCell>
              <TableCell>Last Modified</TableCell>
              <TableCell>Adviser</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Employees</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableContent()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data ? data.count : 0}
        rowsPerPage={contactCasesTable.rowsPerPage}
        page={contactCasesTable.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </CardBase>
  );
}

export default ContactCasesTableCard;

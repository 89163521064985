import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Button, Typography, Grid, Paper } from "@material-ui/core";
import { hideCaseNestTermsBanner } from "../../redux/actions/accountActions";
import WarningIcon from "@material-ui/icons/WarningRounded";

const useStyles = makeStyles((theme) => ({
    paperDets: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "16px",
        fontWeight: 500,
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
    },
    acceptTermsButton: {
        margin: theme.spacing(2),
    },
    icon: {
        color: theme.palette.secondary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            fontSize: "50px",
        },
    },
}));

const LegalTermsCard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleAcceptedTerms = () => {
        dispatch(
            hideCaseNestTermsBanner()
        );
    };


    return (
        <Paper className={classes.paperDets}>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.titleContainer}>
                        <div className={classes.icon}><WarningIcon /></div>
                        <Typography className={classes.title}><strong>Please note</strong> that correspondence between yourself and WorkNest is likely to be covered by legal privilege, and therefore not disclosable. Prior to disclosing any of the information contained in these files, please speak to your adviser.</Typography>
                        <Button
                            className={classes.acceptTermsButton}
                            onClick={handleAcceptedTerms}
                            variant="text"
                            color="secondary"
                        >Dismiss
                  </Button>
                    </div>
                </Grid>
            </Grid>
        </Paper>

  );
};

export default LegalTermsCard;
